import React from "react";
import { Controller } from "react-hook-form";
import { errorStyle, labelStyle } from "../../../../constants/styles";
import CustomQuill from "../../../Inputs/CustomQuill";
import { TextInput } from "djinn-components";
import "../../../../styles/code-editor.css";
import "react-quill/dist/quill.snow.css";

export default function ProjectEditTabDetails({ errors, control }: any) {
  return (
    <div className="mt-8">
      <div>
        <div>
          <label htmlFor="name" className={labelStyle}>
            Endpoint title
          </label>
          <div className="mt-1">
            <Controller
              name="name"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  value={value}
                  placeholder="Enter page title"
                  onChange={onChange}
                  invalid={!!errors.name}
                />
              )}
            />
          </div>
          {errors.name && errors.name.type == "required" && (
            <span className={errorStyle}>Project name is required</span>
          )}

          {errors.name && errors.name.type == "api" && (
            <span className={errorStyle}>{errors.name.message}</span>
          )}
        </div>

        <div className="mt-6 mb-4 sm:col-span-6">
          <label htmlFor="content" className={labelStyle}>
            Endpoint Description
          </label>
          <div className="mt-1">
            <Controller
              name="content"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <CustomQuill value={value} onChange={onChange} />
              )}
            />
            {errors.intro && errors.intro.type == "required" && (
              <span className={errorStyle}>Page content is required</span>
            )}

            {errors.intro && errors.intro.type == "api" && (
              <span className={errorStyle}>{errors.intro.message}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
