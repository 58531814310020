import React from "react";
import { useProjectState } from "../../utils/requiresProject";

const Box = ({
  title,
  value,
}: {
  title: string;
  value: string | JSX.Element;
}) => {
  return (
    <div className="bg-gray-500 w-full p-4 mb-4 rounded-xl shadow-ld bg-opacity-25">
      <h6 className="text-xs text-gray-450">{title}</h6>
      <p className="text-white text-sm font-medium">{value}</p>
    </div>
  );
};
const ProjectDashboardInfo = () => {
  const { project } = useProjectState();

  return (
    <div className="bg-gray-600 bg-opacity-50 h-7/12 w-full rounded-xl flex justify-start items-start flex-col overflow-auto p-10 flex-shrink-0">
      <h5 className="text-white font-medium text-lg mb-6">
        Project Information
      </h5>
      <Box title="Project Name" value={project.project.name} />
      <Box
        title="Documentation URL"
        value={
          <>
            {project.project.custom_url && (
              <>
                <a
                  target="_blank"
                  href={`https://${project.project.custom_url}`}
                  rel="noopener noreferrer"
                >
                  {project.project.custom_url}
                </a>
                &nbsp; {" / "} &nbsp;
              </>
            )}
            <a
              target="_blank"
              href={`https://${project.project.domain}.docbloc.co`}
              rel="noopener noreferrer"
            >
              {project.project.domain}.docbloc.co
            </a>
          </>
        }
      />
      <Box
        title="Availability"
        value={
          project.project.visibility == "public"
            ? "Public"
            : "Private / Password Protected"
        }
      />
      <Box
        title="Versions"
        value={
          <>
            <strong>Total: </strong> {project.project.versions.length} {" / "}
            <strong>Current: </strong> {project.name}
          </>
        }
      />
    </div>
  );
};
export default ProjectDashboardInfo;
