// @ts-nocheck

export const formatJsonForApi = (data) => {
  const groups = [];
  const endpoints = [];

  Object.keys(data.paths).forEach((key) => {
    const items = data.paths[key];

    Object.keys(items).forEach((key2) => {
      const im = items[key2];
      endpoints.push({
        name: im.operationId ?? im.summary,
        endpoint: key,
        content: im.description ?? im.summary,
        method: key2.toUpperCase(),
        group: im.tags && im.tags.length ? im.tags[0] : null,
        parameters: parseParameters(im.parameters), // eslint-disable-line
      });
      if (im.tags && im.tags.length) groups.push(...im.tags);
    });
  });

  return {
    project_name: data.info.title,
    project_intro: data.info.title,
    base_url: data.servers[0].url,
    endpoint_groups: [...new Set(groups)],
    group_content: {},
    endpoints,
  };
};

const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

const parseParameters = (params) => {
  try {
    return params.map((param) => {
      return {
        description: param.description,
        name: param.name,
        required: !!param.required,
        type: getObjectType(param.schema), // eslint-disable-line
        items: [],
      };
    });
  } catch (error) {
    return [];
  }
};

export const validateJsonObject = (obj) => {
  // check the open api version is 3.0 or greater
  if (checkVersion(obj.openapi ?? obj.swagger, "3.0.0")) return false; // eslint-disable-line

  if (!obj.hasOwnProperty("paths")) return false;
  if (!obj.hasOwnProperty("info")) return false;

  if (!isValidHttpUrl(obj.servers[0].url)) return false;

  return true;
};

const checkVersion = (oldVer, newVer) => {
  const oldParts = oldVer.split(".");
  const newParts = newVer.split(".");
  for (let i = 0; i < newParts.length; i++) {
    const a = ~~newParts[i]; // parse int
    const b = ~~oldParts[i]; // parse int
    if (a > b) return true;
    if (a < b) return false;
  }
  return false;
};

/**
 *
 * Maps the js object type to a coresponding item that Docbloc accepts and defaults to string
 *
 * @param mixed value the value to check
 * @return string
 */
export const getObjectType = (schema) => {
  switch (schema.type) {
    case "boolean":
      return "boolean";
    case "array":
      return checkIsArrayOrObjectArray(schema); // eslint-disable-line
    case "integer":
    case "double":
    case "number":
      return "numeric";
    default:
      return "string";
  }
};

const checkIsArrayOrObjectArray = () => {
  return "array";
};
