import React from "react";
import { TickIcon } from "djinn-components";

export default function RegisterPasswordSidebar({
  passwordStrength: { characters, uppercase, special, number },
}: any) {
  return (
    <div className="h-full bg-gray-850 w-2/6 max-w-md rounded-r-xl shadow-lg p-4 flex justify-center items-center flex-col flex-shrink-0">
      <div className="flex justify-start text-white px-8 mb-8">
        <div className="pt-1">
          <h2 className="text-lg font-medium">Password must contain</h2>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              characters && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="text-green-600" />
            </span>{" "}
            At least 8 characters
          </p>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              uppercase && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="text-green-600" />
            </span>
            At least 1 upper case letter (A-Z)
          </p>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              special && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="text-green-600" />
            </span>
            At least 1 special character (e.g: !)
          </p>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              number && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="text-green-600" />
            </span>
            At least 1 number (0-9)
          </p>
        </div>
      </div>
    </div>
  );
}
