import React from "react";
import { BsFolderPlus } from "react-icons/bs";
import { BiRocket } from "react-icons/bi";
import { HiOutlineDocument } from "react-icons/hi";
import HelpLink from "../../HelpLink";
import { PlusIcon } from "djinn-components";

export default function AddProjectItemModal({
  onComplete,
  setActiveItem,
}: any) {
  const createGroup = (e: any) => {
    e.preventDefault();
    setActiveItem(
      {
        id: null,
        name: "",
      },
      "group"
    );

    onComplete();
  };

  const createPage = (e: any) => {
    e.preventDefault();

    setActiveItem(
      {
        id: "new",
      },
      "page"
    );

    onComplete();
  };
  const createEndpoint = (e: any) => {
    e.preventDefault();

    setActiveItem(
      {
        id: "new",
      },
      "endpoint"
    );
    onComplete();
  };

  return (
    <div className="p-4">
      <div>
        <PlusIcon className="block w-8 h-8 mx-auto" />
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-xl leading-6 font-medium text-white">
            Create a new item
          </h3>
          <p className="text-xs text-gray-450 mt-1">
            Choose an option below to get started creating items
          </p>
        </div>
      </div>

      <div className="mt-6 mb-4 flex flex-col content-center items-center px-4">
        <a
          className="w-full mt-2 flex items-center content-start bg-gray-800 cursor-pointer rounded-lg shadow-md p-3 hover:bg-gray-900 mb-2 h-24"
          onClick={createGroup}
        >
          <div className="flex items-center justify-center w-12 h-12 rounded-full mr-3 flex-shrink-0">
            <BsFolderPlus size={30} />
          </div>
          <div>
            <h3 className="text-md leading-none font-medium text-white">
              Group
            </h3>
            <p className="text-sm font-light text-gray-450 leading-none pt-1">
              Create a group to organise your endpoints and content pages
              allowing for easier navigation
            </p>
          </div>
        </a>

        <a
          className="w-full mt-2 flex items-center content-start bg-gray-800 cursor-pointer w-100 rounded-lg shadow-md p-3 hover:bg-gray-900 mb-2 h-24"
          onClick={createEndpoint}
        >
          <div className="flex items-center justify-center w-12 h-12 rounded-full mr-3 flex-shrink-0">
            <BiRocket size={30} />
          </div>
          <div>
            <h3 className="text-md leading-none font-medium text-white">
              Endpoint
            </h3>
            <p className="text-sm font-light text-gray-450 w-100 leading-none pt-1">
              Create an API endpoint with content, parameters and example
              responses
            </p>
          </div>
        </a>

        <a
          className="w-full mt-2 flex items-center content-start bg-gray-800 cursor-pointer rounded-lg shadow-md p-3 hover:bg-gray-900 mb-2 h-24"
          onClick={createPage}
        >
          <div className="flex items-center justify-center w-12 h-12 rounded-full mr-3 flex-shrink-0">
            <HiOutlineDocument size={30} />
          </div>
          <div>
            <h3 className="text-md leading-none font-medium text-white">
              Content Page
            </h3>
            <p className="text-sm font-light text-gray-450 leading-none pt-1">
              Create a simple page that can be used to give users more detail on
              a specific feature
            </p>
          </div>
        </a>
      </div>
      <HelpLink
        text="Need help creating items?"
        link="guides/creating-project-items"
      />
    </div>
  );
}
