import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, TickIcon, TextInput } from "djinn-components";
import { useAlert } from "react-alert";
import axios from "../plugins/axios";
import AuthSidebar from "../components/AuthSidebar";
import { errorStyle } from "../constants/styles";
import LoadingComponent from "../components/LoadingComponent";
import { useNavigate, useSearchParams } from "react-router-dom";

interface JoinFormProps {
  invite_token: string;
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
}
export default function JoinPage() {
  const navigate = useNavigate();
  const alert = useAlert();
  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get("invite_token");

  const [passwordStrength, setPasswordStrength] = useState({
    characters: false,
    uppercase: false,
    special: false,
    number: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm<JoinFormProps>({
    defaultValues: {
      invite_token: inviteToken as string,
    },
    shouldUnregister: false,
  });

  const [loading, setLoading] = useState(false);

  const [inviteData, setInviteData] = useState<any>(null);

  const watchPassword = watch("password");

  useEffect(() => {
    if (watchPassword) {
      setPasswordStrength({
        characters: watchPassword.length > 7,
        uppercase: /[A-Z]/.test(watchPassword),
        special: /[\W_]/.test(watchPassword),
        number: /[0-9]/.test(watchPassword),
      });
    } else {
      setPasswordStrength({
        characters: false,
        uppercase: false,
        special: false,
        number: false,
      });
    }
  }, [watchPassword]);

  const validateInvitation = useCallback(async () => {
    try {
      const { data } = await axios.post("validate-invite", {
        token: inviteToken,
      });

      setValue("name", data.name);
      setValue("email", data.email);
      setValue("invite_token", data.token);
      setInviteData(data);
    } catch (error) {
      navigate("/register");
    }
  }, [inviteToken, navigate, setValue]);

  useEffect(() => {
    if (inviteToken) {
      validateInvitation();
    } else {
      navigate("/register");
    }
  }, [inviteToken, navigate, validateInvitation]);

  const onFinish = async (values: JoinFormProps) => {
    setLoading(true);
    try {
      await axios.post("/register", values);
      navigate(`/not-verified?email=${values.email}`);
    } catch (error) {
      alert.show("Failed to register, please check details.", {
        type: "error",
      });
    }
    setLoading(false);
  };

  if (inviteData === null) {
    return (
      <div className="bg-black w-screen h-screen top-0 left-0">
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div className="flex bg-gray-800">
      <AuthSidebar title="Join Docbloc">
        <p className="text-sm font-light text-gray-450">
          You have been invited to join{" "}
          <strong className="text-white font-medium">
            {inviteData.account}
          </strong>
          , please complete the registration form to continue.
        </p>
        <div className="mt-6">
          <h2 className="text-md font-medium">Password must contain</h2>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              passwordStrength.characters && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="text-green-600" />
            </span>{" "}
            At least 8 characters
          </p>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              passwordStrength.uppercase && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="text-green-600" />
            </span>
            At least 1 upper case letter (A-Z)
          </p>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              passwordStrength.special && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="text-green-600" />
            </span>
            At least 1 special character (e.g: !)
          </p>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              passwordStrength.number && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="text-green-600" />
            </span>
            At least 1 number (0-9)
          </p>
        </div>
      </AuthSidebar>
      <div className="min-h-screen flex justify-between items-center w-full">
        <div className="min-h-screen flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8 w-full">
          <form onSubmit={handleSubmit(onFinish)} className="w-full max-w-sm">
            <input
              type="hidden"
              {...register("invite_token", {
                required: true,
              })}
            />
            <input
              type="hidden"
              {...register("name", {
                required: true,
              })}
            />
            <input
              type="hidden"
              {...register("email", {
                required: true,
              })}
            />

            {errors.invite_token && errors.invite_token.type === "required" && (
              <span className={errorStyle}>Token is required</span>
            )}

            <TextInput id="name" value={inviteData.name} disabled />

            <div className="mt-6 rounded-md shadow-sm">
              <TextInput
                type="email"
                placeholder="Email"
                value={inviteData.email}
                disabled
              />
            </div>

            <div className="mt-6">
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    type="password"
                    placeholder="Password"
                    value={value}
                    onChange={onChange}
                    invalid={!!errors.password}
                  />
                )}
              />
              <div className="mt-6" />
              <Controller
                name="password_confirmation"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    type="password"
                    value={value}
                    onChange={onChange}
                    invalid={!!errors.password}
                    placeholder="Password Confirmation"
                  />
                )}
              />
            </div>
            {errors.password && errors.password.type === "required" && (
              <span className={errorStyle}>Password is required</span>
            )}

            {errors.password && errors.password.type === "api" && (
              <span className={errorStyle}>{errors.password.message}</span>
            )}

            <div className="mt-8">
              <Button
                text="Create account"
                type="submit"
                buttonStyle="green"
                loading={loading}
                disabled={loading}
              />
            </div>

            <p className="mt-2 text-sm text-gray-450 p-1">
              By signing up to Docbloc you agree to our
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="font-bold"
                href="https://docbloc.co/terms-and-conditions"
              >
                Terms of Use
              </a>
              and our
              <a
                className="font-bold"
                href="https://docbloc.co/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}
