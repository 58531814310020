import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import ProjectSettingsIcon from "../Icons/ProjectSettingsIcon";
import ReactTooltip from "react-tooltip";
import {
  DropDownMenuBare,
  LogoutIcon,
  CogsIcon,
  EditProjectIcon,
  HomeIcon,
  Avatar,
} from "djinn-components";
import ProIcon from "../Icons/ProIcon";
import { useProjectState } from "../../utils/requiresProject";
import { useAuth } from "../../utils/auth/useAuth";

const Sidebar: React.FC<any> = ({ permission, showVersionSelect }) => {
  const location = useLocation();
  const { project } = useProjectState();
  const { logout } = useAuth();

  const MenuItem = ({
    Icon = EditProjectIcon,
    title,
    to = `/${project.id}/edit`,
  }: any) => {
    const isActive =
      (to != `/project/${project.id}` && location.pathname.includes(to)) ||
      (to == `/project/${project.id}` && to == location.pathname);

    return (
      <NavLink
        to={to}
        data-tip={title}
        className={`flex justify-center items-center group mt-2 font-medium text-sm text hover:text-white transition ease-in-out duration-150 p-3 ${
          isActive ? "text-white" : "text-gray-450"
        }`}
      >
        <Icon className={`h-6 w-6 ${isActive && "text-green-600"}`} />
      </NavLink>
    );
  };

  return (
    <div className="flex flex-col w-20 flex-shrink-0 bg-gray-850 shadow rounded-r-xl">
      <div className="flex flex-col h-0 flex-1">
        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto p-2">
          <div className="flex items-center justify-center flex-shrink-0 p-2">
            <img
              className="h-10 w-10 text-white"
              src="/icon.png"
              style={{ marginLeft: "2px" }}
              alt="Docbloc logo"
            />
          </div>
          <nav className="mt-5 flex items-center flex-col justify-center">
            <MenuItem
              title="Dashboard"
              to={`/project/${project.id}`}
              Icon={HomeIcon}
            />

            <MenuItem title="Content" to={`/project/${project.id}/edit`} />
            {permission("admin") && (
              <>
                <MenuItem
                  title="Settings"
                  to={`/project/${project.id}/settings`}
                  Icon={ProjectSettingsIcon}
                />

                <MenuItem
                  title="Upgrade"
                  to={`/project/${project.id}/pro`}
                  Icon={ProIcon}
                />
              </>
            )}
          </nav>
          <ReactTooltip place="right" type="dark" effect="solid" />
        </div>
        <div className="flex items-center justify-center mb-5 flex-col">
          <DropDownMenuBare
            button={
              <Avatar img={project.project.icon} text={project.project.name} />
            }
            className="absolute -left-2.5 bottom-14 bg-gray-900 mt-8 rounded shadow-xl w-80 text-white z-20"
          >
            <Link
              className="p-4 text-sm flex items-center hover:bg-gray-950"
              to="/profile"
            >
              <CogsIcon className="block w-5 h-5 mr-3" size={18} />
              <span>Edit Profile</span>
            </Link>
            <Link
              className="p-4 text-sm flex items-center hover:bg-gray-950"
              to="/account"
            >
              <CogsIcon className="block w-5 h-5 mr-3" size={18} />
              <span>Team Settings</span>
            </Link>
            <Link
              className="p-4 text-sm flex items-center hover:bg-gray-950"
              to="/choose-project"
            >
              <CogsIcon className="block w-5 h-5 mr-3" size={18} />
              <span>Switch Project</span>
            </Link>
            <button
              className="w-full p-4 text-sm flex items-center hover:bg-gray-950 rounded-b"
              onClick={logout}
            >
              <LogoutIcon className="block w-5 h-5 mr-3" size={18} />
              <span>Logout</span>
            </button>
            <div
              className="absolute left-4 rounded-sm"
              style={{
                bottom: "-1rem",
                width: 0,
                height: 0,
                borderLeft: "1rem solid transparent",
                borderRight: "1rem solid transparent",
                borderTop: "1rem solid #110f15",
              }}
            ></div>
          </DropDownMenuBare>
          {project.project.features.includes("versioning") && (
            <button
              onClick={showVersionSelect}
              className="bg-gray-400 text-xs w-9/12 rounded-full text-black p-1 mt-4 font-medium truncate"
            >
              {project.name}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
