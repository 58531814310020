import React from "react";
import { CardElement } from "@stripe/react-stripe-js";

const CreditCardInput: React.FC<any> = ({
  invalid = false,
  disabled = false,
  theme = "dark",
}) => {
  const themeStyles =
    theme == "dark"
      ? "bg-gray-700 placeholder-gray-450 text-white focus:ring-white"
      : "bg-gray-450 bg-opacity-25 focus:ring-black placeholder-gray-450 text-black";

  return (
    <CardElement
      options={{
        style: {
          base: {
            iconColor: "white",
            color: "white",
            fontWeight: "500",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
              color: "#fce883",
            },
            "::placeholder": {
              color: "light-gray",
            },
          },
          invalid: {
            iconColor: "red",
            color: "red",
          },
        },
      }}
      className={`form-input p-4 block w-full sm:text-sm sm:leading-5 border-none focus:border-none rounded-3xl shadow-sm ring-1 transition duration-500 ease-in-out focus:outline-none disabled:opacity-75 ${themeStyles} ${
        invalid ? "ring-red-600" : "ring-transparent"
      } ${disabled ? "cursor-not-allowed" : "cursor-text"}`}
    />
  );
};

export default CreditCardInput;
