/* eslint import/no-named-as-default-member: "off" */
import React, { useEffect, useState } from "react";
import useConfirmDelete from "../../../utils/withConfirmDelete";
import ParameterFieldNavItem from "./NavItem";
import ModalContainer, { createModal } from "react-modal-promise";
import AddItemModal from "./AddItemModal";
import PropTypes from "prop-types";
import { PlusIcon } from "djinn-components";
const minHeight = "550px";

const ParameterField: React.FC<any> = ({ parameters, updateParams }) => {
  const deleteParameter = useConfirmDelete();

  const [currParams, setCurrParams] = useState(parameters);

  const NewItemModal = (props: any) => <AddItemModal {...props} />;

  const addItemModal = createModal(NewItemModal);

  useEffect(() => {
    setCurrParams(parameters);
  }, [parameters]);

  const addParam = async (ret = false) => {
    try {
      const value = await addItemModal({
        description: "The Users email address",
        name: "email_address",
        type: "string",
      });
      if (ret) return value;
      if (value) {
        updateParams([...currParams, value]);
      }
    } catch (error) {}
  };

  const deleteItem = async (index: number) => {
    const currentParams = JSON.parse(JSON.stringify(currParams));
    updateParams(
      currentParams.filter((itm: any, ind: number) => ind !== index)
    );
  };

  return (
    <div
      className={`mt-2 pb-1 flex items-start justify-between h-full`}
      style={{ minHeight }}
    >
      <div
        className={`w-full bg-gray-700 bg-opacity-50 ml-1 h-full rounded-md shadow overflow-hidden`}
        style={{
          minHeight,
          height: currParams.length === 0 ? minHeight : "fit-content",
        }}
      >
        {currParams.length === 0 ? (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <div className="text-gray-400 max-w-sm text-center">
              No parameters to display, select an option to get started
            </div>
            <div className="flex mt-4">
              <button
                type="button"
                className="h-8 mb-1 bg-gray-900 hover:bg-gray-950 rounded-full px-6 flex justify-center items-center text-white text-xs ring-0 ring-transparent mr-2"
              >
                Watch tutorial
              </button>

              <button
                type="button"
                className="h-8 mb-1 bg-green-600 hover:bg-green-700 rounded-full px-4 flex justify-center items-center text-white text-xs ring-0 ring-transparent ml-2"
                onClick={() => addParam()}
              >
                Create new <PlusIcon className="ml-4 w-4 h-4" />
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="w-full p-6">
              {currParams.map((param: any, index: number) => (
                <ParameterFieldNavItem
                  key={index}
                  {...{ param, index, createItem: addParam }}
                  deleteParam={() =>
                    deleteParameter("DELETE PARAMETER", () => deleteItem(index))
                  }
                  setParam={(updatedParam: any) => {
                    const updatedParams = JSON.parse(
                      JSON.stringify(currParams)
                    );
                    updatedParams[index] = updatedParam;
                    updateParams(updatedParams);
                  }}
                  duplicateItem={(newParam: any) => {
                    newParam.name = newParam.name + "_copy";
                    const updatedParams = JSON.parse(
                      JSON.stringify(currParams)
                    );
                    updatedParams.push(newParam);
                    updateParams(updatedParams);
                  }}
                />
              ))}
              <button
                type="button"
                className="rounded bg-green-600 hover:bg-green-700 text-gray-300 hover:text-white flex items-center p-3 font-medium"
                onClick={() => addParam()}
              >
                New parameter <PlusIcon className="block w-5 h-5 ml-4" />
              </button>
            </div>
          </>
        )}
        <ModalContainer />
      </div>
    </div>
  );
};

ParameterField.defaultProps = {
  parameters: [],
};

const ItemShape = PropTypes.shape({
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([
    "string",
    "numeric",
    "array",
    "boolean",
    "object_array",
    "object",
  ]).isRequired,
  validation: PropTypes.string,
  items: PropTypes.array,
});

ParameterField.propTypes = {
  defaultActive: PropTypes.number,
  parameters: PropTypes.arrayOf(ItemShape),
};

export default ParameterField;
