import React, { useRef, useState } from "react";
import { useAlert } from "react-alert";
import axios from "../../plugins/axios";

export default function FileUploadButton({
  id = "file_upload",
  text = "Upload",
  fileType = "embedded_image",
  onComplete = () => {},
}: any) {
  const alert = useAlert();
  const fileInput = useRef(null);

  const [loading, setLoading] = useState(false);

  const saveImage = (event: any) => {
    const images = event.target.files;

    if (!images[0]) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", images[0]);

    axios
      .post(`files/${fileType}`, formData)
      .then(({ data }) => {
        alert.show("Image uploaded, remember to save!", { type: "success" });
        onComplete(data);
      })
      .catch(() => {
        alert.show("Image does not meet requirements", { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <input
        id={id}
        type="file"
        name="file"
        hidden
        ref={fileInput}
        accept="image/*"
        onChange={saveImage}
      />
      <button
        type="button"
        onClick={() => (fileInput.current! as HTMLInputElement).click()}
        disabled={loading}
        className="bg-white py-2 px-4 text-gray-800 text-xs font-medium rounded-full hover:bg-gray-300 cursor-pointer hover:text-gray-900"
      >
        {loading ? "Loading ..." : text}
      </button>
    </>
  );
}
