import React from "react";
import { errorStyle, labelStyle } from "../../../constants/styles";
import { TextInput } from "djinn-components";
import SelectField from "../SelectField";
import YesNoField from "../YesNo";

const ParameterFieldDetailsWindow: React.FC<any> = ({
  item,
  updateParamValue,
}) => {
  return (
    <div className="px-2">
      <div className="px-4 mb-4">
        <label htmlFor="label" className={labelStyle}>
          Label
        </label>
        <TextInput
          id="label"
          value={item.name}
          onChange={(e: any) => updateParamValue(e.target.value, "name")}
          invalid={item.name == ""}
        />

        {item.name == "" && (
          <span className={errorStyle}>Parameter Label is required</span>
        )}
      </div>

      <div className="px-4 mb-4">
        <label htmlFor="type" className={labelStyle}>
          Type
        </label>

        <SelectField
          selected={item.type}
          onSelect={(value: any) => updateParamValue(value, "type")}
          options={[
            { value: "string", key: "String" },
            { value: "numeric", key: "Numeric" },
            { value: "boolean", key: "Boolean" },
            { value: "array", key: "Array" },
            { value: "object", key: "Object" },
            { value: "object_array", key: "Object Array" },
          ]}
        />
      </div>

      <div className="px-4 mb-4">
        <label htmlFor="description" className={labelStyle}>
          Description
        </label>
        <TextInput
          type="textarea"
          id="description"
          value={item.description}
          invalid={item.description == ""}
          onChange={(e: any) => updateParamValue(e.target.value, "description")}
        />
        {item.description == "" && (
          <span className={errorStyle}>Parameter Description is required</span>
        )}
      </div>

      <div className="px-4 mb-4 mt-4">
        <label htmlFor="required" className={labelStyle}>
          Is this a required parameter
        </label>
        <YesNoField
          id="required"
          value={item.required ?? false}
          onChange={(value: any) => updateParamValue(value, "required")}
        />
      </div>

      <div className="px-4 mb-4">
        <label htmlFor="label" className={labelStyle}>
          Validation
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <TextInput
            id="label"
            placeholder="e.g. min:3 | max,4 | unique:notes,id"
            value={item.validation}
            onChange={(e: any) =>
              updateParamValue(e.target.value, "validation")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ParameterFieldDetailsWindow;
