import React, { useCallback, useEffect, useState } from "react";

import axios from "../../../plugins/axios";
import { Button } from "djinn-components";
import { useAlert } from "react-alert";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../LoadingComponent";
import { useForm } from "react-hook-form";
import Tabs from "../../Tabs";
import { errorParser } from "../../../utils/errorParser";
import ProjectEditTabDetails from "./Tabs/Details";
import ProjectEditTabSettings from "./Tabs/Settings";
import ProjectEditTabResponses from "./Tabs/Responses";
import ProjectEditTabRequests from "./Tabs/Requests";
import ProjectEditEndpointInfo from "./EndpointInfo";
import ProjectEditTabParameters from "./Tabs/Parameters";
import HelpLink from "../../HelpLink";
import useCheckProjectPermissions from "../../../utils/useCheckProjectPermission";
import { useProjectState } from "../../../utils/requiresProject";

const ProjectEditEndpoint = () => {
  const { project, reloadProject: refreshProject } = useProjectState();
  const navigate = useNavigate();
  const params = useParams<{ project: string; endpointId: string }>();
  const alert = useAlert();
  const permission = useCheckProjectPermissions(project);

  const [isNew, setIsNew] = useState(false);

  const [theEndpoint, setTheEndpoint] = useState<any>({
    name: "",
    content: "",
    request: [],
    response: [],
    method: "GET",
    params: [],
    endpoint: "",
  });

  const [apiLoading, setApiLoading] = useState(false);

  const [theRequest, setTheRequest] = useState("");
  const [theResponse, setTheResponse] = useState("");

  const [responseValid, setResponseValid] = useState(true);
  const [requestValid, setRequestValid] = useState(true);

  const getDefaults = () => {
    return theEndpoint;
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
    formState,
    reset,
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: getDefaults(),
  });

  const getEndpointData = useCallback(async () => {
    const endpointId = parseInt(params.endpointId as string);

    const { data } = await axios.get(`endpoints/${endpointId}`);

    setTheEndpoint(data);
    reset(data);

    try {
      setTheResponse(JSON.stringify(data.response, null, 4));
      setTheRequest(JSON.stringify(data.request, null, 4));
    } catch (error) {}
  }, [params.endpointId, reset]);

  useEffect(() => {
    if (params.endpointId !== "new") {
      try {
        getEndpointData();
      } catch (error) {
        navigate(`/project/${params.project}/edit`);
      }
      setIsNew(false);
    } else {
      setIsNew(true);
      setTheResponse(JSON.stringify({}, null, 4));
      setTheRequest(JSON.stringify({}, null, 4));
    }
  }, [getEndpointData, navigate, params.endpointId, params.project]);

  const updateItem = (values: any) =>
    axios.put(`endpoints/${theEndpoint.id}`, values);

  const createItem = (values: any) =>
    axios.post(`endpoints`, {
      ...values,
      project_version_id: project.id,
    });

  const formSubmit = async (values: any) => {
    const nErrors: any = {};

    if (!responseValid) {
      nErrors.response = true;
      setError("response", {
        type: "api",
        message:
          "The response example is not valid, please double check the entered value is correct!",
      });
    }

    if (!requestValid) {
      nErrors.request = true;

      setError("request", {
        type: "api",
        message:
          "The request example is not valid JSON, please double check the entered value is correct!",
      });
    }

    if (Object.keys(nErrors).length > 0) {
      alert.show("Failed to save!", { type: "error" });
      return;
    }

    setApiLoading(true);

    const method = params.endpointId == "new" ? createItem : updateItem;

    try {
      const { data } = await method(values);

      reset(data);

      alert.show(isNew ? "Endpoint Saved!" : "Endpoint updated!", {
        type: "success",
      });

      if (isNew) {
        await refreshProject();
        navigate(`/project/${params.project}/edit/endpoint/${data.id}`);
      } else {
        setTheEndpoint(data);
      }
    } catch (error) {
      errorParser(error, setError);
      alert.show("Failed to save!", { type: "error" });
    }
    setApiLoading(false);
  };

  const closeEndpoint = () => {
    navigate(`/project/${params.project}/edit`);
  };

  const getTabs = () => {
    const tabs = [
      {
        title: "Details",
        component: <ProjectEditTabDetails {...{ errors, control }} />,
      },
      {
        title: "Parameters",
        component: <ProjectEditTabParameters {...{ errors, control }} />,
      },
      {
        title: "Request Sample",
        component: (
          <ProjectEditTabRequests
            {...{
              errors,
              control,
              setRequestValid,
              requestValid,
              theRequest,
              setTheRequest,
            }}
          />
        ),
      },
      {
        title: "Response Sample",
        component: (
          <ProjectEditTabResponses
            {...{
              errors,
              control,
              setResponseValid,
              responseValid,
              theResponse,
              setTheResponse,
            }}
          />
        ),
      },
    ];

    if (permission("admin") && !isNew)
      tabs.push({
        title: "Settings",
        component: (
          <ProjectEditTabSettings
            {...{
              theEndpoint,
              closeEndpoint,
              refreshProject,
              setApiLoading,
              apiLoading,
            }}
          />
        ),
      });

    return tabs;
  };

  if (!theEndpoint)
    return (
      <div>
        <LoadingComponent />
      </div>
    );

  return (
    <div className="mx-auto w-full max-w-4xl pt-10 mb-10">
      <form onSubmit={handleSubmit(formSubmit)}>
        {/* <Prompt
          when={formState.isDirty}
          message="You have unsaved changes are you sure you want to leave?"
        /> */}
        <ProjectEditEndpointInfo {...{ project, control, errors }} />

        <div className="bg-gray-250 w-full rounded-lg mt-10 bg-opacity-50 p-10 pb-6 relative">
          <Tabs tabs={getTabs()} defaultActive={0} />

          <div className="w-full flex justify-between items-center mt-6">
            <HelpLink
              text="Need help with editing endpoints?"
              link="guides/editing-endpoint-details"
            />
            <div className="flex items-center">
              <button
                type="button"
                hidden={!formState.isDirty || (formState.isDirty && isNew)}
                onClick={() => reset(getDefaults())}
                className="text-sm text-white mr-6 opacity-50 hover:opacity-100 cursor-pointer transition ease-in-out duration-150 focus:outline-none"
              >
                Discard Changes
              </button>
              <div className="w-32 block">
                <Button
                  text={isNew ? "Save endpoint" : "Save changes"}
                  type="submit"
                  buttonStyle="green"
                  disabled={apiLoading || !formState.isDirty}
                  loading={apiLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProjectEditEndpoint;
