import { Modal } from "djinn-components";
import React, { useState } from "react";
import ACPFieldEditorHolder from "../FieldHolder";
import ACPWysiwygEditModal from "../Modals/Wysiwyg";
import { ACPFieldComponentProps, ACPWysiwygWidget } from "../utils";

const AcpWysiwygWidgetEditor: React.FC<
  ACPFieldComponentProps<ACPWysiwygWidget>
> = ({ value, onChange, index, onDelete }) => {
  const [shown, setShown] = useState(false);
  return (
    <>
      <ACPFieldEditorHolder
        editClick={() => setShown(true)}
        deleteClick={onDelete}
        index={index}
        dndId={value.id}
      >
        <div className="flex items-center justify-center flex-col p-4 h-full">
          <h4 className="font-bold text-md">Rich Text</h4>
        </div>
      </ACPFieldEditorHolder>
      <Modal shown={shown} onClose={() => setShown(false)}>
        <ACPWysiwygEditModal
          initialValue={value.content}
          onSave={(v) => onChange({ ...value, content: v })}
          onComplete={() => setShown(false)}
        />
      </Modal>
    </>
  );
};

export default AcpWysiwygWidgetEditor;
