/* eslint import/no-named-as-default-member: "off" */
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.css";
import "jsoneditor-react/es/editor.min.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import customHistory, { DocblocRouter } from "./utils/history";
import ReactGA from "react-ga4";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      // if (event.exception) {
      //   Sentry.showReportDialog({ eventId: event.event_id, });
      // }
      return event;
    },
  });
}

ReactGA.initialize("G-EPX4R4K7EZ");

ReactDOM.render(
  <React.StrictMode>
    <DocblocRouter history={customHistory}>
      <App />
    </DocblocRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
