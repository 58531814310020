import React, { useState } from "react";
import HelpLink from "../../HelpLink";
import { useProjectState } from "../../../utils/requiresProject";
import FormHolder from "../FormHolder";
import Form from "../../Form";
import FormFieldWrapper from "../../Form/FormFieldWrapper";
import FileUploadButton from "../../FileUploadButton";

const AppImageHolder: React.FC<any> = ({
  id,
  children,
  setImage,
  image,
  label = "",
  description = "",
  type = "app_icon",
  sClass = "h-16 w-16 overflow-hidden",
  imageBg = "bg-transparent",
}) => {
  const [imageUrl, setImageUrl] = useState<string | null>(image);
  return (
    <div className="w-full mb-6 grid grid-cols-2 gap-8">
      <div className="bg-gray-500 bg-opacity-50 rounded-xl p-4 flex justify-start items-center">
        {imageUrl && imageUrl !== "" && (
          <div
            className={`w-3/12 h-full flex-shrink-0 flex justify-center items-center rounded-xl ${imageBg} mr-4`}
          >
            <img
              src={imageUrl}
              className={sClass}
              alt="Project Logo"
              style={{ objectFit: "contain" }}
            />
          </div>
        )}

        <div className="rounded-md shadow-sm">
          <h5 className="font-medium text-sm">{label}</h5>
          <p className="text-gray-400 text-xs">{description}</p>
          <div className="flex items-center mt-4">
            <FileUploadButton
              id={id}
              onComplete={({ url, path }: any) => {
                setImageUrl(url);
                setImage(path);
              }}
              fileType={type}
            />
            {imageUrl && imageUrl !== "" && (
              <button
                type="button"
                onClick={() => {
                  setImage(null);
                  setImageUrl(null);
                }}
                className="text-xs text-white mr-3 ml-3 opacity-50 hover:opacity-100 cursor-pointer transition ease-in-out duration-150 focus:outline-none"
              >
                Remove
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="text-left text-xs text-gray-400 font-light leading-5 flex flex-col items-start justify-center pl-10">
        <h6 className="text-xs text-white font-medium">Requirements:</h6>
        {children}
      </div>
    </div>
  );
};

const ProjectSettingsLogos: React.FC = () => {
  const { project, reloadProject } = useProjectState();

  const getDefaults = () => {
    return {
      icon: project.project.icon,
      logo: project.project.logo,
      logo_light: project.project.logo_light,
    };
  };

  return (
    <FormHolder>
      <Form
        endpoint={`projects/${project.project.id}`}
        method="put"
        buttonTitle="Save Changes"
        successToast="Project settings update successful"
        getDefaults={getDefaults}
        transformPayload={(data: any) => {
          const newData: any = {};

          const previous = getDefaults();

          if (previous.icon !== data.icon) newData.icon = data.icon;
          if (previous.logo !== data.logo) newData.logo = data.logo;
          if (previous.logo_light !== data.logo_light)
            newData.logo_light = data.logo_light;

          return newData;
        }}
        onCompleted={async () => {
          await reloadProject();
        }}
        helpComp={
          <HelpLink
            text="Need help with project design?"
            link="guides/design-and-layout"
          />
        }
        render={({ errors, control }: any) => (
          <div>
            <FormFieldWrapper
              id="icon"
              label="Project Icon"
              errors={errors}
              control={control}
              required={false}
              render={({ onChange, value }: any) => (
                <AppImageHolder
                  setImage={onChange}
                  id="app_icon"
                  image={value}
                  label="Project Icon"
                  description="Icon that will be displayed as the app favicon."
                >
                  <ul>
                    <li>Icon must be square.</li>
                    <li>Recommended size: 150px x 150px.</li>
                    <li>Maximum file size of 512kb.</li>
                  </ul>
                </AppImageHolder>
              )}
            />

            <FormFieldWrapper
              id="logo"
              label="Project Logo"
              errors={errors}
              control={control}
              required={false}
              render={({ onChange, value }: any) => (
                <AppImageHolder
                  setImage={onChange}
                  id="logo"
                  type="app_logo"
                  image={value}
                  sClass="w-3/4 h-24"
                  imageBg="bg-gray-200"
                  label="Project Logo"
                  description="This logo will be displayed in the doc’s sidebar."
                  imagePlaceholder="/sample-logos/logo-dark.svg"
                >
                  <ul>
                    <li>Logo must be visible on a light background</li>
                    <li>Recommended image 400px x 160px</li>
                    <li>Maximum file size of 1mb.</li>
                  </ul>
                </AppImageHolder>
              )}
            />

            <FormFieldWrapper
              id="logo_light"
              label="Project Logo - Dark mode"
              errors={errors}
              control={control}
              required={false}
              render={({ onChange, value }: any) => (
                <AppImageHolder
                  setImage={onChange}
                  id="logo_dark"
                  type="app_logo"
                  image={value}
                  sClass="w-3/4 h-24"
                  imageBg="bg-gray-900"
                  label="Project Logo - Dark mode"
                  description="This logo will be displayed in the doc’s sidebar."
                  imagePlaceholder="/sample-logos/logo-light.svg"
                >
                  <ul>
                    <li>Logo must be visible on a dark background</li>
                    <li>Recommended image 400px x 160px</li>
                    <li>Maximum file size of 1mb.</li>
                  </ul>
                </AppImageHolder>
              )}
            />
          </div>
        )}
      />
    </FormHolder>
  );
};

export default ProjectSettingsLogos;
