import { Button } from "djinn-components";
import React, { useState } from "react";
import { ACPRowWidget } from "../utils";

interface ACPRowEditModalProps {
  initialValue?: ACPRowWidget;
  onSave: (v: ACPRowWidget) => void;
  onComplete: () => void;
}

type RowColumnsType = 1 | 2 | 3 | 4 | 5 | 6;

const ACPRowEditModal: React.FC<ACPRowEditModalProps> = ({
  initialValue,
  onComplete,
  onSave,
}) => {
  const [value, setValue] = useState({
    columns: initialValue?.config.columns ?? 1,
  });

  return (
    <div>
      <h4 className="text-xl mb-6">Row Settings</h4>
      <label className="text-sm mb-2 block">Amount of columns:</label>

      <div className="grid grid-cols-6 gap-3">
        {Array.from(Array(6).keys()).map((ii) => (
          <div
            role="button"
            tabIndex={-1}
            className={`rounded-full ${
              ii + 1 === value.columns ? "bg-green-500" : "bg-gray-700"
            } p-2 text-center cursor-pointer hover:bg-green-600`}
            onClick={() =>
              setValue((prev) => ({
                ...prev,
                columns: (ii + 1) as RowColumnsType,
              }))
            }
          >
            {ii + 1}
          </div>
        ))}
      </div>
      <div className="mt-8 flex items-center">
        <span className="w-4/10 block mr-4">
          <Button
            text="Cancel"
            buttonStyle="red"
            type="button"
            onClick={() => onComplete()}
          />
        </span>
        <Button
          text={"Save Changes"}
          buttonStyle="green"
          type="button"
          onClick={() => {
            onSave({
              ...initialValue,
              config: {
                columns: value.columns,
              },
            } as ACPRowWidget);
            onComplete();
          }}
        />
      </div>
    </div>
  );
};

export default ACPRowEditModal;
