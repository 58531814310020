import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers";
import { UsersReduxState } from "../reducers/users";
import { RolesReduxState } from "../reducers/roles";

let store: any; // eslint-disable-line
// @ts-expect-error
if (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__) {
  // eslint-disable-line
  const composeEnhancers = // eslint-disable-line
    // @ts-expect-error
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line
  store = createStore(
    rootReducer,
    /* preloadedState, */ composeEnhancers(applyMiddleware(thunkMiddleware))
  );
} else {
  store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
}

export type RootState = {
  users: UsersReduxState;
  user: any;
  auth: any;
  roles: RolesReduxState;
};

export type AppDispatch = typeof store.dispatch;

export default store;
