import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, TextInput } from "djinn-components";
import { useAlert } from "react-alert";
import axios from "../plugins/axios";
import AuthSidebar from "../components/AuthSidebar";
import { errorStyle } from "../constants/styles";
import ImageUpload from "../components/Inputs/ImageUpload";
import HelpLink from "../components/HelpLink";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useAuth } from "../utils/auth/useAuth";

const CreateAccountPage: React.FC<any> = () => {
  const alert = useAlert();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);

  const account = useSelector<RootState>(
    ({ user: { currentAccount } }) => currentAccount
  ) as any;

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm();

  const onFinish = (values: any) => {
    setLoading(true);
    return axios
      .post("/accounts", values)
      .then(({ data: { id } }) => {
        window.localStorage.setItem("Acc0", id);
        window.location.replace("/choose-project");
      })
      .catch(
        ({
          response: {
            data: { errors: resErrors },
          },
        }) => {
          if (resErrors.logo)
            setError("logo", { type: "api", message: resErrors.logo[0] });
          if (resErrors.name)
            setError("name", { type: "api", message: resErrors.name[0] });
          alert.show("Failed to setup new account", { type: "error" });
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const cancelCreateTeam = () => {
    // if user has other teams redirect to team select
    // window.location.replace("/choose-project");
    logout();
  };
  return (
    <div className="flex bg-gray-800">
      <AuthSidebar title="Create new team">
        <div>
          <p className="text-sm text-gray-500">
            Enter your company details to create a new team
          </p>
        </div>
      </AuthSidebar>

      <div className="min-h-screen flex flex-col justify-between items-center py-12 sm:px-6 lg:px-8 w-full">
        <div />
        <form onSubmit={handleSubmit(onFinish)} className="w-full max-w-sm">
          <Controller
            name="logo"
            control={control}
            defaultValue={null}
            render={({ field: { onChange, value } }) => (
              <ImageUpload
                placeholder="Logo or Avatar"
                value={value}
                onChange={onChange}
              />
            )}
          />

          <div className="mt-8">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  id="name"
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.account_name}
                  placeholder="Company / Team Name"
                />
              )}
            />

            {errors.name && errors.name.type == "required" && (
              <span className={errorStyle}>Full name is required</span>
            )}

            {errors.name && errors.name.type == "api" && (
              <span className={errorStyle}>{errors.name.message}</span>
            )}
          </div>

          <div className="mt-8 text">
            <Button
              type="submit"
              loading={loading}
              disabled={loading}
              buttonStyle="green"
              text="Create Team"
            />
            <div
              role="button"
              onClick={cancelCreateTeam}
              className="text-center p-6 font-bold text-sm text-gray-450 hover:text-gray-300 cursor-pointer"
            >
              LOG OUT
            </div>
          </div>
        </form>

        <HelpLink
          text="Need help with teams?"
          link="guides/working-with-teams"
        />
      </div>
    </div>
  );
};

export default CreateAccountPage;
