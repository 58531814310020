import { DropDownMenuBare, PlusIcon } from "djinn-components";
import React from "react";
import { ACPWidgetFieldType, acpWidgetTypes } from "./utils";

interface ACPAddItemProps {
  disabled?: Array<ACPWidgetFieldType>;
  onComplete: (type: ACPWidgetFieldType) => void;
}

const ACPAddItem: React.FC<ACPAddItemProps> = ({
  disabled = [],
  onComplete,
}) => {
  return (
    <div className="flex items-center justify-center mt-2">
      <DropDownMenuBare
        button={<PlusIcon className="ml-2 w-4 h-4 block" size={16} />}
        className="absolute -left-36 bottom-10 bg-gray-900 mt-8 rounded shadow-xl w-80 text-white z-20 p-3"
      >
        <h4 className="font-medium text-sm mb-4">Choose Widget Type</h4>
        {acpWidgetTypes
          .filter((type) => !disabled.includes(type.type))
          .map((type) => (
            <div
              key={type.type}
              onClick={() => onComplete(type.type)}
              className="bg-gray-700 py-2 px-2 my-2 rounded-sm font-medium text-sm cursor-pointer hover:bg-gray-800"
            >
              {type.title}
            </div>
          ))}
        <div
          className="absolute rounded-sm"
          style={{
            bottom: "-1rem",
            width: 0,
            height: 0,
            left: "50%",
            transform: "translateX(-50%)",
            borderLeft: "1rem solid transparent",
            borderRight: "1rem solid transparent",
            borderTop: "1rem solid #110f15",
          }}
        ></div>
      </DropDownMenuBare>
    </div>
  );
};

export default ACPAddItem;
