import React from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { AiOutlineDelete, AiOutlineDrag } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { useDNDContext } from "./utils";

interface ACPFieldEditorHolderProps {
  editClick?: () => void;
  deleteClick: () => void;
  dndId: string;
  index: number;
}

const ACPFieldEditorHolder: React.FC<ACPFieldEditorHolderProps> = ({
  editClick,
  deleteClick,
  children,
  dndId,
  index,
}) => {
  const dndContext = useDNDContext();

  const getItemStyle = (
    isDragging: boolean,
    isDropping: boolean,
    draggableStyle: any
  ) => ({
    ...draggableStyle,
    ...(isDragging
      ? {
          bottom: 0,
        }
      : {}),
  });

  return (
    <Draggable
      key={dndId}
      index={index}
      draggableId={`draggable-${dndId}`}
      isDragDisabled={
        dndContext.currentDragable !== `draggable-${dndId}` &&
        dndContext.currentDragable !== undefined
      }
    >
      {(
        draggableProvided: DraggableProvided,
        draggableSnapshot: DraggableStateSnapshot
      ) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          style={getItemStyle(
            draggableSnapshot.isDragging,
            draggableSnapshot.combineTargetFor != null,
            draggableProvided.draggableProps.style
          )}
          className={`border rounded-md border-gray-450 mb-2 h-full relative bg-gray-750 pb-10`}
          data-dragging={
            draggableSnapshot.isDragging && !draggableSnapshot.isDropAnimating
          }
        >
          {children}

          <div className="border-t border-gray-450 rounded-b-sm flex items-center justify-start text-gray-200 hover:text-white font-medium cursor-pointer h-10 absolute bottom-0 left-0 w-full z-0">
            <div className="flex items-center justify-center w-full">
              <div
                className="w-6 h-6 mx-1 bg-gray-600 hover:bg-gray-700 text-white flex items-center justify-center"
                {...draggableProvided.dragHandleProps}
              >
                <AiOutlineDrag size={18} />
              </div>
              {editClick !== undefined && (
                <div
                  onClick={editClick}
                  className="w-6 h-6  mx-1 bg-green-500 hover:bg-green-800 text-white flex items-center justify-center"
                >
                  <BiEdit size={18} />
                </div>
              )}
              <div
                onClick={deleteClick}
                className="w-6 h-6  mx-1 bg-red-500 hover:bg-red-600 text-white flex items-center justify-center"
              >
                <AiOutlineDelete size={18} color="white" />
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default ACPFieldEditorHolder;
