import React, { useState } from "react";
import { Modal } from "djinn-components";
import ProjectEditEndpoint from "../../../components/ProjectEdit/Endpoint";
import ProjectEditPage from "../../../components/ProjectEdit/Page";
import axios from "../../../plugins/axios";
import Menu from "./Menu/index";
import { arrayMove, remapItem, idIndexArr } from "./utils";
import EditDashboard from "./EditDashboard";
import AddProjectItemModal from "../../../components/Modals/AddProjectItem";
import AddEditGroupModal from "../../../components/Modals/AddEditGroup";
import { Route, Routes, useNavigate } from "react-router-dom";
import ProjectEditGroup from "../../../components/ProjectEdit/Group";
import { useProjectState } from "../../../utils/requiresProject";
import EditingOldVersionWarning from "../../../components/EditingOldVersionWarning";

const ProjectEditScreen: React.FC = () => {
  const { project, reloadProject, tempSetProjectItems } = useProjectState();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const [selectedItemObject, setSelectedItemObject] = useState(null);

  const [selectedItemType, setSelectedItemType] = useState(null);
  const [activeString, setActiveString] = useState<string | null>(null);

  const setSelectedItem = (item: any, type: any) => {
    if (["endpoint", "page"].includes(type)) {
      navigate(`/project/${project.id}/edit/${type}/${item.id}`);
      return;
    }
    setSelectedItemType(type);
    setSelectedItemObject(item);

    if (item == null || type == null) {
      setActiveString(null);
    } else {
      setActiveString(`${type}-${item.id}`);
    }
  };

  const [showCreateItem, setShowCreateItem] = useState(false);

  const [dndLoading, setDndLoading] = useState(false);

  const updateOrderingApi = async (items: any) => {
    try {
      await axios.put(`projects/${project.id}/ordering`, { items });
      await reloadProject();
    } catch (error) {}
  };

  const moveToNewDestination = async (
    type: "group" | "endpoint" | "page",
    id: number,
    destType: string,
    destId: number
  ) => {
    const unGroup = destType === "un";

    try {
      await axios.put(`projects/${project.id}/change-group`, {
        type,
        id,
        group_id: unGroup ? null : destId,
      });
    } catch (error) {}
  };

  const onDragEnd = async ({
    destination,
    source,
    draggableId,
    combine,
  }: any) => {
    if (!destination && !combine) {
      return;
    }

    if (combine && !combine.draggableId.includes("group-")) {
      return;
    }
    // return early if nothing has changed

    setDndLoading(true);

    // first we should update the current project so the resort looks instant
    let alteredItems = JSON.parse(JSON.stringify(project.all_items));

    const detId = destination ? destination.droppableId : combine.draggableId;

    // if the destination droppable is different to the current one then make an api request to move it.
    if (detId !== source.droppableId) {
      const destSplit = detId.split("-");
      const destType = destSplit[0];
      const destId = parseInt(destSplit[1]) ?? null;

      const sourceSplit = draggableId.split("-");
      const type = sourceSplit[0];
      const id = parseInt(sourceSplit[1]);
      await moveToNewDestination(type, id, destType, destId);
    }

    // // if the destinations are the same it means that we should update the sorting.
    if (detId === source.droppableId) {
      // check if we are working inside a group or if its the main obj
      if (detId == "un-grouped") {
        alteredItems = arrayMove(alteredItems, source.index, destination.index);
      } else {
        alteredItems = alteredItems.map((item: any, itmInd: any) =>
          remapItem(
            item,
            itmInd,
            source.index,
            destination.index,
            parseInt(detId.split("-")[1])
          )
        );
      }
    }

    tempSetProjectItems(alteredItems);

    const sortingUpdateList = alteredItems.map(idIndexArr);

    await updateOrderingApi(sortingUpdateList);

    setDndLoading(false);
  };

  return (
    <div className="flex justify-start items-start absolute inset-0	w-full h-full overflow-hidden">
      <Menu
        search={search}
        setSearch={setSearch}
        onDragEnd={onDragEnd}
        project={project}
        setActiveItem={setSelectedItem}
        dndLoading={dndLoading}
        activeString={activeString}
        createItem={() => setShowCreateItem(true)}
        reloadList={async () => {
          setDndLoading(true);
          await reloadProject();
          setDndLoading(false);
        }}
      />
      <div className="w-full h-screen overflow-auto p-8">
        <EditingOldVersionWarning />

        <Routes>
          <Route
            path={``}
            element={<EditDashboard setShowCreateItem={setShowCreateItem} />}
          />

          <Route path={`/page/:pageId`} element={<ProjectEditPage />} />
          <Route
            path={`/endpoint/:endpointId`}
            element={<ProjectEditEndpoint />}
          />

          <Route path={`/group/:groupId`} element={<ProjectEditGroup />} />
        </Routes>

        <Modal
          shown={showCreateItem}
          onClose={() => setShowCreateItem(false)}
          dismissible={true}
          contentSize="sm:max-w-xl sm:w-full"
        >
          <AddProjectItemModal
            setActiveItem={setSelectedItem}
            onComplete={() => setShowCreateItem(false)}
          />
        </Modal>

        <Modal
          shown={selectedItemType == "group"}
          onClose={() => setSelectedItem(null, null)}
          dismissible={true}
          contentSize="sm:max-w-xl sm:w-full"
        >
          <AddEditGroupModal
            group={selectedItemObject}
            onComplete={() => setSelectedItem(null, null)}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ProjectEditScreen;
