import axios from "../../../plugins/axios";
import { Button, useConfirmDelete, UserAddIcon } from "djinn-components";
import React, { useState } from "react";
import { PaymentMethodModel } from "../../../types/models";
import BillingCodeBlock from "../../CardBlock";
import { useAlert } from "react-alert";

interface EditPaymentMethodModalProps {
  paymentMethod: PaymentMethodModel;
  onClose: () => void;
}

const EditPaymentMethodModal: React.FC<EditPaymentMethodModalProps> = ({
  paymentMethod,
  onClose,
}) => {
  const alert = useAlert();

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [makeDefaultLoading, setMakeDefaultLoading] = useState(false);
  const deletePaymentMethod = useConfirmDelete();

  const deletePM = async () => {
    console.log("works");
    setDeleteLoading(true);
    try {
      await axios.delete(`account/payment-methods/${paymentMethod.id}`);

      alert.success("Payment method deleted");
      onClose();
    } catch (error) {
      alert.error(
        // @ts-ignore
        error?.response?.data?.message ?? "Failed to delete payment method"
      );
    }
    setDeleteLoading(false);
  };

  const makeDefault = async () => {
    setMakeDefaultLoading(true);
    try {
      await axios.post(
        `account/payment-methods/${paymentMethod.id}/make-default`
      );

      alert.success("Default payment method updated");
      onClose();
    } catch (error) {
      alert.error("Failed to update default payment method");
    }
    setMakeDefaultLoading(false);
  };

  return (
    <div>
      {/* <div className="mx-auto flex items-center justify-center h-8 w-8">
        <UserAddIcon />
      </div> */}
      <div className="mt-3 text-center sm:mt-5">
        <h3
          className="text-lg leading-6 font-medium text-white"
          id="modal-headline"
        >
          Payment method options
        </h3>
        <p className="leading-5 font-light text-xs text-gray-400">
          Enter details bellow to add a new payment method to your account.
        </p>
        <div className="my-6 w-[300px] mx-auto text-left">
          <BillingCodeBlock
            card={paymentMethod}
            onClick={() => {
              // setEditPaymentMethod(pm);
            }}
            key={1}
          />
        </div>
        {!paymentMethod.default && (
          <button
            className="bg-gray-800 w-32 p-2 rounded-md shadow-md mx-auto"
            onClick={() => {
              if (!makeDefaultLoading) {
                makeDefault();
              }
            }}
          >
            {makeDefaultLoading ? "Loading..." : "Make default"}
          </button>
        )}
        <div className="flex mt-10">
          <div className="mr-4">
            <Button
              text="Cancel"
              type="button"
              buttonStyle="white"
              onClick={onClose}
            />
          </div>
          <Button
            text="Delete Payment method"
            buttonStyle="red"
            type="submit"
            loading={deleteLoading}
            disabled={deleteLoading}
            onClick={() =>
              deletePaymentMethod("DELETE PAYMENT METHOD", () => deletePM())
            }
          />
        </div>
      </div>
    </div>
  );
};

export default EditPaymentMethodModal;
