import React from "react";

export default function DropField({
  getRootProps,
  getInputProps,
  isDragActive,
  type = "Open API",
  text = "You can import any OpenApi/Swagger V3 JSON file by uploading a file here and following the import workflow",
}: any) {
  return (
    <div className="py-4">
      <div
        {...getRootProps()}
        className={`group h-72 bg-transparent ${
          isDragActive ? "border-green-800" : "border-gray-400 border-dashed"
        } rounded-xl border-2 p-2 flex flex-col justify-center items-center transition-all duration-150 ease-in-out hover:border-gray-600 hover:border-solid cursor-pointer`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="text-green-800">Drop the files here</p>
        ) : (
          <>
            <h5 className="text-white text-xl mt-2 mb-2 font-medium">
              Importing: {type}
            </h5>
            <p className="text-gray-450 font-light max-w-md text-center">
              {text}
            </p>
            <p className="text-sm font-medium text-green-500 group-hover:text-green-700 mt-6">
              Drag a file here or click to import
            </p>
          </>
        )}
      </div>
    </div>
  );
}
