import { Modal } from "djinn-components";
import React, { useState } from "react";
import ACPFieldEditorHolder from "../FieldHolder";
import ACPTableEdit from "../Modals/Table";
import { ACPFieldComponentProps, ACPTableWidget } from "../utils";

const AcpTableWidgetEditor: React.FC<
  ACPFieldComponentProps<ACPTableWidget>
> = ({ value, index, onChange, onDelete }) => {
  const [shown, setShown] = useState(false);

  return (
    <>
      <ACPFieldEditorHolder
        editClick={() => setShown(true)}
        deleteClick={onDelete}
        index={index}
        dndId={value.id}
      >
        <div className="flex items-center justify-center flex-col p-4 h-full">
          <h4 className="font-bold text-md">Table</h4>
        </div>
      </ACPFieldEditorHolder>
      <Modal shown={shown} onClose={() => setShown(false)}>
        <ACPTableEdit
          initialValue={{ ...value }}
          onSave={(v) => onChange({ ...value, ...v })}
          onComplete={() => setShown(false)}
        />
      </Modal>
    </>
  );
};

export default AcpTableWidgetEditor;
