import React, { useCallback, useState } from "react";
import axios from "axios";
import { useAlert } from "react-alert";

import { LoadingIcon, TrashIcon } from "djinn-components";
import { useDropzone } from "react-dropzone";

const ContentImageUpload: React.FC<any> = ({
  value,
  onChange,
  type = "embedded_image",
}) => {
  const alert = useAlert();

  const [loading, setLoading] = useState(false);

  const [uploadedFullUrl, setUploadedFullUrl] = useState(value ?? null);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!acceptedFiles[0]) return;

      setLoading(true);

      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);

      try {
        const { data } = await axios.post(`files/${type}`, formData);
        onChange(data.path, data.url);
        setUploadedFullUrl(data.url);
      } catch (error) {
        alert.show("Image does not meet requirements", { type: "error" });
      }
      setLoading(false);
    },
    [alert, onChange, type]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ["image/jpeg", "image/png", "image/webp"],
  });

  return (
    <div className="w-full flex flex-col items-center justify-center text-gray-500 relative cursor-pointer">
      <div className="relative w-full">
        {loading ? (
          <div
            className={`h-20 w-20 flex items-center justify-center text-primary`}
          >
            <LoadingIcon size={20} />
          </div>
        ) : (
          <>
            {uploadedFullUrl ? (
              <>
                <div className="border border-gray-500 p-5 rounded-md w-full flex items-center justify-center bg-gray-300 shadow-lg">
                  <img
                    src={uploadedFullUrl ?? "/user-placeholder.png"}
                    alt="ContentImageUploadField"
                    className="max-h-48"
                  />
                </div>

                <div onClick={() => setUploadedFullUrl(null)}>
                  <TrashIcon className="w-6 h-6 absolute block top-3 right-3 text-red-500 hover:text-red-400" />
                </div>
              </>
            ) : (
              <div
                {...getRootProps()}
                className={`group h-72 bg-transparent ${
                  isDragActive
                    ? "border-green-800"
                    : "border-gray-400 border-dashed"
                } rounded-xl border-2 p-2 flex flex-col justify-center items-center transition-all duration-150 ease-in-out hover:border-gray-500 hover:border-solid cursor-pointer w-full`}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p className="text-green-800">Drop Image here</p>
                ) : (
                  <>
                    <p className="text-sm font-medium text-green-500 group-hover:text-green-700 mt-6">
                      Drag a file here or click to click to choose file
                    </p>
                    <em>(Only jpeg, png and webp images are allowed)</em>
                  </>
                )}
              </div>
            )}
            <ul className="my-4 text-left text-gray-400 list-disc ml-4 text-sm font-medium">
              <li>Image should be less than 500kb in size</li>
              <li>
                Image should not contain a transparent background as image can
                be displayed on both light and dark backgrounds
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default ContentImageUpload;
