import { ProjectVersionType } from "../../types/models";

export const isSetupCompleted = (project: ProjectVersionType) => {
  return (
    project.project.name &&
    project.intro &&
    project.base_url &&
    project.project.icon &&
    project.project.logo &&
    project.project.logo_light &&
    project.project.visibility !== "draft" &&
    project.all_items.length > 0
  );
};

export const getSetupSteps = (project: ProjectVersionType) => {
  return [
    {
      title: "Project Details",
      description:
        "Update your project details by adding your title, base URL and introduction",
      link: `/project/${project.id}/settings`,
      completed: project.project.name && project.intro && project.base_url,
    },
    {
      title: "Add a logo",
      description:
        "Upload your project logo and icons to keep consistent with your branding",
      link: `/project/${project.id}/settings/design/logos-and-icons`,
      completed:
        project.project.icon &&
        project.project.logo &&
        project.project.logo_light,
    },
    {
      title: "Select your project availability",
      description:
        "Set your documentation availability to public or private only viewing",
      link: `/project/${project.id}/settings/details`,
      completed: project.project.visibility !== "draft",
    },
    {
      title: "Create a new item",
      description:
        "Get started with the editor and begin creating new items for your project",
      link: `/project/${project.id}/edit?newItem=1`,
      completed: project.all_items.length > 0,
    },

    {
      title: "View your Documentation",
      description: "Preview your documentation and see how it looks",
      link: `https://${project.project.domain}.docbloc.co`,
      completed: false,
    },
  ];
};
