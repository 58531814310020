import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import settingsRoutes from "../../pages/project/settings/paths";
import { ProjectSettingsMenuOption } from "../../types";
import { useProjectState } from "../../utils/requiresProject";

interface MenuItemProps {
  title: string;
  to: string;
}
const SettingsSidebar: React.FC<any> = () => {
  const { project } = useProjectState();
  const location = useLocation();

  const MenuItem: React.FC<MenuItemProps> = ({
    title,
    to = `/${project.id}/settings`,
  }) => {
    const isActive = location.pathname === to.replace(/\/$/, "");
    return (
      <NavLink
        to={to}
        className={`flex justify-start items-center group text-sm text hover:text-white transition ease-in-out duration-150 py-1 ${
          isActive ? "text-green-600" : "text-gray-450"
        }`}
      >
        {title}
      </NavLink>
    );
  };

  return (
    <div className="w-2/12 max-w-sm bg-gray-750 flex-shrink-0 flex-grow-0 h-screen rounded-r-xl shadow p-10">
      <h1 className="text-xl mb-6">Settings</h1>

      {settingsRoutes.map(
        (group: ProjectSettingsMenuOption, groupIndex: number) => (
          <div key={groupIndex}>
            <h4 className="text-sm font-medium text-gray-300">{group.title}</h4>
            {group.children
              .filter((rt) => !rt.condition || rt.condition(project))
              .map((route, index) => (
                <MenuItem
                  key={index}
                  to={`/project/${project.id}/settings${route.path}`}
                  title={route.title}
                />
              ))}
            <br />
          </div>
        )
      )}
    </div>
  );
};

export default SettingsSidebar;
