import React, { useCallback, useEffect, useRef, useState } from "react";
import { BiCheck } from "react-icons/bi";
import { HiSelector } from "react-icons/hi";

interface SelectFieldProps {
  selected?: string | number | null;
  options?: { key: string | number; value: string | number }[];
  label?: string;
  onSelect?: (val: any) => void;
  transparent?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  emptyPlaceholder?: any;
}
const SelectField: React.FC<SelectFieldProps> = ({
  selected = null,
  options = [],
  label = "-- Choose Option --",
  onSelect = () => {},
  transparent = false,
  invalid = false,
  disabled = false,
  emptyPlaceholder = "No options available",
}) => {
  const wrapperRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const getSelected = useCallback(() => {
    try {
      if (!selected) return label;
      return options.find((option: any) => option.value === selected)?.key;
    } catch (error) {
      return label;
    }
  }, [selected, label, options]);

  const [selectedTitle, setSelectedTitle] = useState(getSelected);

  useEffect(() => {
    function handleClickOutside(event: any) {
      // @ts-expect-error
      if (wrapperRef?.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setSelectedTitle(getSelected());
  }, [selected, getSelected]);

  return (
    <div className="space-y-1" ref={wrapperRef}>
      <div className="relative cursor-pointer">
        <span className="inline-block w-full">
          <button
            type="button"
            disabled={disabled}
            onClick={() => {
              if (!disabled) {
                setShowDropdown(!showDropdown);
              }
            }}
            className={`transition-all ease-in-out relative w-full rounded-3xl border ${
              transparent
                ? "bg-transparent border-transparent"
                : "bg-gray-700  border-gray-700"
            } p-4 text-left focus:ring-0 focus:outline-none active:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5 ${
              invalid ? "border-red-600" : ""
            } ${showDropdown ? "rounded-b-none" : ""} ${
              showDropdown || selected != null ? "text-white" : "text-gray-400"
            } ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
          >
            <span className="block truncate">{selectedTitle}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none text-gray-600">
              <HiSelector size={20} color="white" />
            </span>
          </button>
        </span>

        <div
          className={`w-full rounded-b-3xl rounded-t-none bg-gray-700 shadow-md overflow-auto ${
            showDropdown ? "absolute z-50" : "hidden"
          }`}
        >
          <ul
            tabIndex={-1}
            className="max-h-60 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
          >
            {options.map((option: any, key: number) => (
              <li
                className="text-gray-900 select-none relative p-4 pr-9 hover:bg-gray-500 cursor-pointer text-left"
                key={key}
                onClick={() => {
                  onSelect(option.value);
                  setShowDropdown(false);
                }}
              >
                <span className="font-normal block truncate text-gray-400">
                  {option.key}
                </span>

                <span className="text-gray-400 absolute inset-y-0 right-0 flex items-center pr-4">
                  {option.value == selected && <BiCheck />}
                </span>
              </li>
            ))}
            {options.length == 0 && (
              <li className="text-gray-900 select-none relative p-4 pr-9 hover:bg-gray-500 cursor-pointer text-left">
                <span className="font-normal block truncate text-gray-400">
                  {emptyPlaceholder}
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SelectField;
