import React, { useState } from "react";
import { AiOutlineCopy } from "react-icons/ai";
import axios from "../../../plugins/axios";
import { Button, KeysIcon, TextInput } from "djinn-components";
import FieldErrors from "../../FieldErrors";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlert } from "react-alert";

const CreatePasswordModal = ({ onClose, project, getItems }: any) => {
  const alert = useAlert();

  const [addPasswordTitle, setAddPasswordTitle] = useState<string>("");

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [passwordCreated, setPasswordCreated] = useState<string | null>(null);

  const cancelAddPassword = () => {
    onClose();
  };

  const addUserFormSubmit = (e: any) => {
    e.preventDefault();

    const randPassword = Array(15)
      .fill(
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@£$%^`&"
      )
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");

    setLoading(true);

    return axios
      .post(`project-passwords/${project.id}`, {
        name: addPasswordTitle,
        password: randPassword,
      })
      .then(() => {
        getItems();
        setPasswordCreated(randPassword);
      })
      .catch((errr) => {
        setErrors(errr.response.data.errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={addUserFormSubmit} className="p-5 px-12">
      {passwordCreated == null && (
        <div>
          <div className="mx-auto flex items-center justify-center h-8 w-8">
            <KeysIcon />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg leading-6 font-medium text-white"
              id="modal-headline"
            >
              Create Docbloc password
            </h3>
            <p className="leading-5 font-light text-xs text-gray-400">
              Enter a unique title below that will be used to identify your
              password
            </p>

            <div className="mt-4 relative rounded-md shadow-sm">
              <TextInput
                placeholder="Password title"
                value={addPasswordTitle}
                onChange={(e) => {
                  setAddPasswordTitle(e.currentTarget.value);
                  setErrors({});
                }}
                invalid={Object.keys(errors).includes("name")}
              />
              <FieldErrors errors={errors} id="name" />
            </div>
          </div>
        </div>
      )}

      {passwordCreated != null && (
        <div>
          <div className="mx-auto flex items-center justify-center h-8 w-8">
            <KeysIcon />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg leading-6 font-medium text-white"
              id="modal-headline"
            >
              Password created
            </h3>
            <p className="leading-5 font-light text-xs text-gray-400">
              Your new password has been created, please save it somewhere safe
              as you will not be able to view it again.
            </p>
            <div className="mt-6">
              <CopyToClipboard
                text={passwordCreated}
                onCopy={() =>
                  alert.show("Password copied to clipboard!", {
                    type: "success",
                  })
                }
              >
                <div className="mt-3 rounded-full p-4 bg-gray-700 flex justify-between text-gray-300 hover:text-gray-400 hover:bg-gray-800 cursor-pointer transition duration-500 ease-in-out">
                  <p> {passwordCreated}</p>
                  <AiOutlineCopy size={24} />
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      )}

      <div className="mt-5 sm:mt-6">
        {passwordCreated == null && (
          <div className="flex mt-10">
            <div className="mr-4">
              <Button
                text="Cancel"
                type="button"
                buttonStyle="transparent-white"
                loading={loading}
                disabled={loading}
                onClick={cancelAddPassword}
              />
            </div>
            <Button
              text="Create Password"
              type="submit"
              buttonStyle="green"
              loading={loading}
              disabled={loading}
            />
          </div>
        )}
        {passwordCreated != null && (
          <Button
            text="I have saved my password"
            onClick={cancelAddPassword}
            buttonStyle="green"
          />
        )}
      </div>
    </form>
  );
};

export default CreatePasswordModal;
