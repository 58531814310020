import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getSetupSteps } from "./ProjectDashboard.utils";
import { TickIcon } from "djinn-components";
import { useProjectState } from "../../utils/requiresProject";

const ProjectLaunchpad: React.FC = () => {
  const { project } = useProjectState();

  const [launchItems] = useState(getSetupSteps(project));

  const LaunchItem = ({
    title = "",
    description = "",
    link = "",
    completed = false,
  }: any) => {
    return (
      <Link
        to={link}
        onClick={(e) => {
          if (link.startsWith("http")) {
            e.preventDefault();
            window.open(link, "_blank")!.focus();
          }
        }}
        className="bg-gray-500 bg-opacity-25 rounded-xl p-4 flex items-center justify-start pr-6 mb-5"
      >
        <div className="w-10 h-10 bg-gray-450 rounded-full mr-3 bg-opacity-50 flex items-center justify-center">
          {completed && <TickIcon className="w-5 text-green-600" />}
        </div>
        <div>
          <h4 className="font-medium text-sm">{title}</h4>
          <p className="font-light text-gray-400 text-xs">{description}</p>
        </div>
      </Link>
    );
  };

  return (
    <div className="pt-10 max-w-5xl mx-auto">
      <div className="bg-gray-600 bg-opacity-50 h-full w-full rounded-xl flex justify-between items-center overflow-auto p-10">
        <div>
          <h4 className="font-medium">Project Launchpad</h4>
          <p className="font-light text-gray-400 text-sm mb-6">
            Get started with your project to unlock your project dashboard
          </p>
          {launchItems.map((item, ke) => (
            <LaunchItem {...item} key={ke} />
          ))}
        </div>
        <img
          src="/feature-request.svg"
          alt="Project Launchpad"
          className="px-5"
        />
      </div>
    </div>
  );
};

export default ProjectLaunchpad;
