import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../plugins/axios";
import useConfirmDelete from "../../utils/withConfirmDelete";
import { TextInput, Button } from "djinn-components";
import { errorStyle, labelStyle } from "../../constants/styles";
import Tabs from "../Tabs";
import { Controller, useForm } from "react-hook-form";
import { errorParser } from "../../utils/errorParser";
import LoadingComponent from "../LoadingComponent";
import HelpLink from "../HelpLink";
import useCheckProjectPermissions from "../../utils/useCheckProjectPermission";
import { useProjectState } from "../../utils/requiresProject";
import AdvancedContentPageBuilder from "../Inputs/AdvancedContentPageBuilder";
import {
  ACPRowWidget,
  ACPWidget,
} from "../Inputs/AdvancedContentPageBuilder/utils";
import useFeatureCheck from "../../utils/useCheckFeature";
import CustomQuill from "../Inputs/CustomQuill";

interface FormProps {
  name: string;
  content: string;
  acp_content: Array<ACPWidget | ACPRowWidget>;
}
const ProjectEditPage = () => {
  const { project, reloadProject: refreshProject } = useProjectState();
  const params = useParams<any>();
  const navigate = useNavigate();
  const alert = useAlert();
  const permission = useCheckProjectPermissions(project);
  const deletePage = useConfirmDelete();
  const featureCheck = useFeatureCheck();

  const [currentPage, setCurrentPage] = useState<any>({});
  const [isNew, setIsNew] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);

  const getDefaults = useCallback(() => {
    return {
      name: currentPage.name ?? "",
      content: currentPage.content ?? "",
      acp_content: (currentPage.acp_content ?? []) as Array<ACPWidget>,
    };
  }, [currentPage]);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isDirty },
    reset,
  } = useForm<FormProps>({
    reValidateMode: "onChange",
    defaultValues: getDefaults(),
  });

  useEffect(() => {
    reset(getDefaults());
  }, [currentPage, getDefaults, reset]);

  const resetState = () => {
    setCurrentPage({});
    setIsNew(false);
    setApiLoading(false);
  };

  useEffect(() => {
    resetState();
    if (params.pageId !== "new") {
      try {
        const page = project.pages.find(
          (iPage: any) => iPage.id == parseInt(params.pageId as string)
        );
        if (!page) throw new Error();
        setCurrentPage(page);
        setPageLoaded(true);
      } catch (error) {
        navigate(`/project/${params.project}/edit`);
      }
      setIsNew(false);
    } else {
      setIsNew(true);
      setPageLoaded(true);
      setCurrentPage({
        name: "",
        content: "",
      });
    }
  }, [params, navigate, project]);

  const updateItem = (values: any) =>
    axios.put(`pages/${currentPage.id}`, values);

  const createItem = (values: any) =>
    axios.post(`pages`, { ...values, project_version_id: params.project });

  const formSubmit = async (values: any) => {
    setApiLoading(true);
    const method = isNew ? createItem : updateItem;

    try {
      const { data } = await method(values);

      alert.show(isNew ? "Page Saved!" : "Page updated!", {
        type: "success",
      });

      if (isNew) {
        reset();
        await refreshProject();
        navigate(`/project/${params.project}/edit/page/${data.id}`);
      } else {
        setCurrentPage(data);
      }
    } catch (error) {
      errorParser(error, setError);
      alert.show("Failed to save!", { type: "error" });
    }

    setApiLoading(false);
  };

  const closePage = () => {
    navigate(`/project/${params.project}/edit`);
  };

  const deleteItem = async () => {
    if (isNew) return;
    setApiLoading(true);
    try {
      await axios.delete(`pages/${currentPage.id}`);
      refreshProject();
      closePage();
      alert.show("Page Deleted", {
        type: "success",
      });
    } catch (error) {}
    setApiLoading(false);
  };

  const getTabs = () => {
    const tabs = [
      {
        title: "Page content",
        component: (
          <div className="mt-8">
            <div className="">
              <div className="sm:col-span-4">
                <label htmlFor="name" className={labelStyle}>
                  Page title
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        value={value}
                        placeholder="Enter page title"
                        onChange={onChange}
                        invalid={!!errors.name}
                      />
                    )}
                  />
                  {errors.name && errors.name.type == "required" && (
                    <span className={errorStyle}>Project name is required</span>
                  )}

                  {errors.name && errors.name.type == "api" && (
                    <span className={errorStyle}>{errors.name.message}</span>
                  )}
                </div>
              </div>

              <div className="mt-6 mb-4 sm:col-span-6">
                <label htmlFor="content" className={labelStyle}>
                  Content
                </label>
                <div className="mt-1">
                  {featureCheck("advanced_content_pages") ? (
                    <Controller
                      name="acp_content"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <AdvancedContentPageBuilder
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  ) : (
                    <Controller
                      name="content"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <CustomQuill value={value} onChange={onChange} />
                      )}
                    />
                  )}

                  {errors.content && errors.content.type == "required" && (
                    <span className={errorStyle}>Page content is required</span>
                  )}

                  {errors.content && errors.content.type == "api" && (
                    <span className={errorStyle}>{errors.content.message}</span>
                  )}

                  {featureCheck("advanced_content_pages") &&
                    currentPage.content && (
                      <p className="my-4 text-green-600 text-sm">
                        Your page has been updated to use the pro page editor!
                      </p>
                    )}

                  {!featureCheck("advanced_content_pages") &&
                    currentPage.acp_content &&
                    currentPage.acp_content.length > 0 && (
                      <p className="my-4 text-red-700 text-sm">
                        Your project has downgraded, you no longer have access
                        to the pro page editor, please upgrade your project to
                        the pro plan to view this again.{" "}
                        <strong>
                          All suitable content has been converted to the basic
                          page type
                        </strong>
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ];

    if (permission("admin") && !isNew)
      tabs.push({
        title: "Page settings",
        component: (
          <div className="w-full" style={{ minHeight: "50vh" }}>
            <div className="py-4 mx-auto w-3/4 bg-gray-250 p-6 rounded-lg mt-8">
              <div className="col-span-6 mb-4 flex items-center justify-between">
                <div>
                  <p className="mt-2 text-md text-white">Delete Page</p>
                  <p className="text-sm text-gray-400">
                    This action is not reversible so be sure you want to do it.
                  </p>
                </div>
                <span className="w-32 mt-2 pt-1">
                  <Button
                    text="Delete"
                    type="button"
                    onClick={() => deletePage("DELETE PAGE", deleteItem)}
                    buttonStyle="red"
                    disabled={apiLoading}
                    loading={apiLoading}
                  />
                </span>
              </div>
            </div>
          </div>
        ),
      });

    return tabs;
  };

  if (!pageLoaded)
    return (
      <div>
        <LoadingComponent />
      </div>
    );

  return (
    <div className="mx-auto w-full max-w-4xl pt-10 mb-10">
      {!isNew ? (
        <h1 className="text-lg font-light">
          Editing Page:{" "}
          <strong className="font-medium">{currentPage.name}</strong>
        </h1>
      ) : (
        <h1 className="text-lg font-light">New Page</h1>
      )}

      <p className="text-sm max-w-md font-light text-gray-450">
        Pages are best used for detailing non endpoint data, such as shared
        structures or authentication instructions etc.
      </p>

      <form
        onSubmit={handleSubmit(formSubmit)}
        className="bg-gray-250 w-full rounded-lg mt-10 bg-opacity-50 p-10 pb-6"
      >
        {/* <Prompt
          when={isDirty}
          message="You have unsaved changes are you sure you want to leave?"
        /> */}
        <Tabs tabs={getTabs()} defaultActive={0} />

        <div className="w-full flex justify-between items-center mt-6">
          <HelpLink
            text="Need help with editing pages?"
            link="guides/editing-content-pages"
          />
          <div className="flex items-center">
            <button
              type="button"
              hidden={!isDirty || (isDirty && isNew)}
              onClick={() => reset(getDefaults())}
              className="text-sm text-white mr-6 opacity-50 hover:opacity-100 cursor-pointer transition ease-in-out duration-150 focus:outline-none"
            >
              Discard Changes
            </button>
            <div className="w-32 block">
              <Button
                text={isNew ? "Save page" : "Save changes"}
                type="submit"
                buttonStyle="green"
                disabled={apiLoading || !isDirty}
                loading={apiLoading}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProjectEditPage;
