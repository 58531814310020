import React, { useContext, useState, createContext } from "react";
import { v4 as uuidv4 } from "uuid";

export type ACPWidgetFieldType =
  | "row"
  | "column"
  | "wysiwyg"
  | "image"
  | "table";

export type ACPWidgetType =
  | ACPWidget
  | ACPRowWidget
  | ACPWysiwygWidget
  | ACPColumnWidget
  | ACPTableWidget;

export type ACPWidget = {
  id: string;
  type: ACPWidgetFieldType;
};

export interface ACPRowWidget extends ACPWidget {
  type: "row";
  config: {
    columns: 1 | 2 | 3 | 4 | 5 | 6;
  };
  children: Array<ACPWidget | null>;
}
export interface ACPColumnWidget extends ACPWidget {
  type: "column";
  children: Array<ACPWidget>;
}

export interface ACPWysiwygWidget extends ACPWidget {
  type: "wysiwyg";
  content: any;
}

export interface ACPImageWidget extends ACPWidget {
  type: "image";
  imageUrl: string | null;
}

export interface ACPTableWidget extends ACPWidget {
  type: "table";
  // config: {
  //   rows: number;
  //   columns: number;
  // };
  headers: Array<string>;
  rows: Array<Array<string>>;
}

export interface ACPFieldComponentProps<T> {
  index: number;
  value: T;
  onChange: (val: T) => void;
  onDelete: () => void;
}

export const acpWidgetTypes: Array<{
  title: string;
  children_enabled: boolean;
  type: ACPWidgetFieldType;
}> = [
  {
    title: "Row",
    children_enabled: true,
    type: "row",
  },
  {
    title: "Column",
    children_enabled: true,
    type: "column",
  },
  {
    title: "Rich Text",
    children_enabled: false,
    type: "wysiwyg",
  },
  {
    title: "Image",
    children_enabled: false,
    type: "image",
  },
  {
    title: "Table",
    children_enabled: false,
    type: "table",
  },
];

const CurrentDNDContext = createContext<any>({
  currentDragable: undefined,
  setCurrentDraggable: () => {},
});

export const useDNDContext = () => {
  return useContext<any>(CurrentDNDContext);
};

function useProvideDNDContext() {
  const [currentDragable, setCurrentDraggable] = useState<string | undefined>(
    undefined
  );

  return { currentDragable, setCurrentDraggable };
}

export const ProvideDNDContext = ({ children }: any) => {
  const ctx = useProvideDNDContext();
  return (
    <CurrentDNDContext.Provider value={ctx}>
      {children}
    </CurrentDNDContext.Provider>
  );
};

export const newItemByType = (type: ACPWidgetFieldType): ACPWidgetType => {
  const raw = {
    id: uuidv4(),
    type,
  } as ACPWidget;
  switch (type) {
    case "wysiwyg":
      return {
        ...raw,
        content: "",
      } as ACPWysiwygWidget;

    case "row":
      return {
        ...raw,
        config: {
          columns: 1,
        },
        children: [],
      } as ACPRowWidget;

    case "column":
      return { ...raw, children: [] } as ACPColumnWidget;
    case "image":
      return {
        ...raw,
        imageUrl: null,
      } as ACPImageWidget;

    case "table":
      return { ...raw, headers: [""], rows: [[""]] } as ACPTableWidget;
    default:
      return raw;
  }
};
