import React from "react";
import { MenuItem } from "react-contextmenu";
const ContextMenuItem: React.FC<any> = ({ children, Icon = null, onClick }) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onClick();
  };

  return (
    <MenuItem
      onClick={handleClick}
      className="px-3 py-2 text-sm font-light hover:bg-gray-900 cursor-pointer flex items-center"
    >
      {Icon && <Icon className="block w-4 h-4 mr-3" size={16} />}
      {children}
    </MenuItem>
  );
};

export default ContextMenuItem;
