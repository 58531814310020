import { Button, CogsFilledIcon, DropDownMenuBare } from "djinn-components";
import React, { useState } from "react";
import { arrayMove } from "../../../../utils/object_array.utils";

interface ACPTableEditProps {
  initialValue?: {
    headers: Array<string>;
    rows: Array<Array<string>>;
  };
  onSave: (v: { headers: Array<string>; rows: Array<Array<string>> }) => void;
  onComplete: () => void;
}

const ACPTableEdit: React.FC<ACPTableEditProps> = ({
  initialValue,
  onSave,
  onComplete,
}) => {
  const [value, setValue] = useState({
    headers: initialValue?.headers ?? [],
    rows: initialValue?.rows ?? [],
  });

  const addRow = (index: number) => {
    const newRow = [...new Array(value.headers.length)].map(() => "");
    const rowsToUpdate = [...value.rows, newRow];
    const newRowVal = arrayMove(rowsToUpdate, rowsToUpdate.length - 1, index);
    setValue((prevState) => ({
      ...prevState,
      rows: newRowVal,
    }));
  };

  const deleteRow = (index: number) => {
    setValue((prevState) => ({
      ...prevState,
      rows: prevState.rows.filter((rw, ri) => ri !== index),
    }));
  };

  const addColumn = (index: number) => {
    const newColumns = [...value.headers, ""];
    const newColValue = arrayMove(newColumns, newColumns.length - 1, index);

    const newRows = value.rows.map((row) => {
      const newRow = [...row, ""];
      const newRowVal = arrayMove(newRow, newRow.length - 1, index);
      return newRowVal;
    });

    setValue((prevState) => ({
      ...prevState,
      headers: newColValue,
      rows: newRows,
    }));
  };

  const deleteColumn = (index: number) => {
    const newHeaders = [...value.headers.filter((hd, hi) => hi !== index)];
    const newRows = [
      ...value.rows.map((rw) => rw.filter((rw2, ri2) => ri2 !== index)),
    ];

    setValue((prevState) => ({
      ...prevState,
      headers: newHeaders,
      rows: newRows,
    }));
  };

  return (
    <div className="relative">
      <div
        className={`grid grid-cols-${value.headers.length ?? 1} gap-4 w-[95%]`}
      >
        {value.headers.map((header, index) => (
          <input
            key={`header-${index}`}
            className="border border-gray-100 bg-transparent ring-0 focus:ring-0 outline-none p-2 rounded-md text-[14px]"
            value={header}
            onChange={(e) => {
              const newHeaders = [...value.headers];
              newHeaders[index] = e.target.value;
              setValue((prevVal) => ({
                ...prevVal,
                headers: newHeaders,
              }));
            }}
          />
        ))}
        <div
          className={`col-span-${
            value.headers.length ?? 1
          } bg-white bg-opacity-10`}
          style={{ height: "1px" }}
        ></div>
      </div>

      {value.rows.map((row, index) => (
        <div
          className={`grid grid-cols-${
            value.headers.length ?? 1
          } gap-4 w-[95%] relative my-4`}
          key={`row-${index}`}
        >
          {row.map((cell, cellIndex) => (
            <textarea
              rows={2}
              key={`cell-val-${index}-${cellIndex}`}
              className="text-left border border-gray-500 bg-transparent ring-0 focus:ring-0 outline-none p-2 rounded-md text-sm"
              value={cell}
              onChange={(e) => {
                const newRows = [...value.rows];
                newRows[index][cellIndex] = e.target.value;
                setValue((prevVal) => ({
                  ...prevVal,
                  rows: newRows,
                }));
              }}
            />
          ))}
          <div className="absolute -right-[24px] bottom-[40%]">
            <DropDownMenuBare
              button={
                <div className="my-auto block" role="button" tabIndex={-1}>
                  <CogsFilledIcon size={16} />
                </div>
              }
              className="absolute -left-20 bottom-10 bg-gray-900 rounded shadow-xl w-44 text-white z-20 p-3"
            >
              <div className="transition-opacity delay-300">
                <div
                  className="text-center p-1 m-1 text-xs rounded-md hover:bg-gray-500 cursor-pointer"
                  onClick={() => addRow(index)}
                >
                  Add row above
                </div>
                <div
                  className="text-center p-1 m-1 text-xs rounded-md hover:bg-gray-500 cursor-pointer"
                  onClick={() => addRow(index + 1)}
                >
                  Add row below
                </div>
                <div
                  className="text-center p-1 m-1 text-xs rounded-md hover:bg-gray-500 cursor-pointer"
                  onClick={() => deleteRow(index)}
                >
                  Delete row
                </div>
              </div>

              <div
                className="absolute rounded-sm"
                style={{
                  bottom: "-1rem",
                  width: 0,
                  height: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderLeft: "1rem solid transparent",
                  borderRight: "1rem solid transparent",
                  borderTop: "1rem solid #110f15",
                }}
              ></div>
            </DropDownMenuBare>
          </div>
        </div>
      ))}

      <div
        className={`grid grid-cols-${
          value.headers.length ?? 1
        } gap-4 w-[95%] relative my-4`}
      >
        {value.headers.map((header, index) => (
          <div
            className="flex items-center justify-center"
            key={`column-action-${index}`}
          >
            <DropDownMenuBare
              button={
                <div className="my-auto block" role="button" tabIndex={-1}>
                  <CogsFilledIcon size={16} />
                </div>
              }
              className="absolute -left-20 bottom-10 bg-gray-900 rounded shadow-xl w-44 text-white z-20 p-3"
            >
              <div
                className="text-center p-1 m-1 text-xs rounded-md hover:bg-gray-500 cursor-pointer"
                onClick={() => addColumn(index)}
              >
                Add column to left
              </div>
              <div
                className="text-center p-1 m-1 text-xs rounded-md hover:bg-gray-500 cursor-pointer"
                onClick={() => addColumn(index + 1)}
              >
                Add column to right
              </div>
              <div
                className="text-center p-1 m-1 text-xs rounded-md hover:bg-gray-500 cursor-pointer"
                onClick={() => deleteColumn(index)}
              >
                Delete column
              </div>
              <div
                className="absolute rounded-sm"
                style={{
                  bottom: "-1rem",
                  width: 0,
                  height: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderLeft: "1rem solid transparent",
                  borderRight: "1rem solid transparent",
                  borderTop: "1rem solid #110f15",
                }}
              ></div>
            </DropDownMenuBare>
          </div>
        ))}
      </div>

      <div className="mt-8 flex items-center">
        <span className="w-4/10 block mr-4">
          <Button
            text="Cancel"
            buttonStyle="red"
            type="button"
            onClick={() => onComplete()}
          />
        </span>
        <Button
          text={"Save Changes"}
          buttonStyle="green"
          type="button"
          onClick={() => {
            onSave({ ...initialValue, ...value });
            onComplete();
          }}
        />
      </div>
      <div className="mt-10"></div>
    </div>
  );
};

export default ACPTableEdit;
