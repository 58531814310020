import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateUser } from "../../actions/user";
import { errorStyle, labelStyle } from "../../constants/styles";
import { errorParser } from "../../utils/errorParser";
import { Button, TickIcon, TextInput } from "djinn-components";

export default function ChangePassword({ onClose }: any) {
  const alert = useAlert();
  const dispatch = useDispatch();

  const getDefaults = () => {
    return {
      old_password: "",
      password: "",
      password_confirmation: "",
    };
  };

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
    formState,
    watch,
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: getDefaults(),
  });

  const watchPassword = watch(["password", "password_confirmation"]);

  const [{ characters, uppercase, special, number }, setPasswordStrength] =
    useState({
      characters: false,
      uppercase: false,
      special: false,
      number: false,
    });

  useEffect(() => {
    setPasswordStrength({
      characters: watchPassword[0].length > 7,
      uppercase: /[A-Z]/.test(watchPassword[0]),
      special: /[\W_]/.test(watchPassword[0]),
      number: /[0-9]/.test(watchPassword[0]),
    });
  }, [watchPassword]);

  const submit = async (values: any) => {
    setLoading(true);

    try {
      await dispatch(updateUser(values));
      alert.show("Password updated!", { type: "success" });
      onClose();
    } catch (error) {
      errorParser(error, setError);
      alert.show("Failed to update password!", { type: "error" });
    }

    setLoading(false);
  };

  return (
    <form
      className="absolute w-full h-full flex justify-center items-center bg-gray-600 inset-0 rounded-lg"
      onSubmit={handleSubmit(submit)}
    >
      <div className="w-1/3">
        <div className="pt-1 text-white">
          <h2 className="text-xl font-medium">Change Password</h2>
          <p className="text-xs text-gray-450 mb-4">Password must contain</p>

          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              characters && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="text-green-600" />
            </span>{" "}
            At least 8 characters
          </p>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              uppercase && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="text-green-600" />
            </span>
            At least 1 upper case letter (A-Z)
          </p>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              special && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="text-green-600" />
            </span>
            At least 1 special character (e.g: !)
          </p>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              number && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="text-green-600" />
            </span>
            At least 1 number (0-9)
          </p>
        </div>
      </div>
      <div className="w-1/3">
        <div className="mt-6">
          <label htmlFor="old_password" className={labelStyle}>
            Current Password
          </label>

          <Controller
            name="old_password"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                type="password"
                id="old_password"
                value={value}
                onChange={onChange}
                invalid={!!errors.old_password}
                placeholder="Current password"
              />
            )}
          />
          {errors.old_password && errors.old_password.type == "required" && (
            <span className={errorStyle}>Current password is required</span>
          )}

          {errors.old_password && errors.old_password.type == "api" && (
            <span className={errorStyle}>{errors.old_password.message}</span>
          )}

          <div className="mt-6"></div>
          <label htmlFor="password" className={labelStyle}>
            New Password
          </label>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                type="password"
                placeholder="Password"
                value={value}
                onChange={onChange}
                invalid={!!errors.password}
              />
            )}
          />
          <div className="mt-6"></div>
          <label htmlFor="password_confirmation" className={labelStyle}>
            New Password (Again)
          </label>
          <Controller
            name="password_confirmation"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                type="password"
                id="password_confirmation"
                value={value}
                onChange={onChange}
                invalid={!!errors.password}
                placeholder="Password confirmation"
              />
            )}
          />
        </div>
        {errors.password && errors.password.type == "required" && (
          <span className={errorStyle}>New password is required</span>
        )}

        {errors.password && errors.password.type == "api" && (
          <span className={errorStyle}>{errors.password.message}</span>
        )}

        <div className="w-full flex justify-end items-center mt-6">
          <button
            type="button"
            onClick={() => onClose()}
            className="text-sm text-white mr-6 opacity-50 hover:opacity-100 cursor-pointer transition ease-in-out duration-150 focus:outline-none"
          >
            Cancel
          </button>
          <div className="w-56">
            <Button
              text="Save Password"
              loading={loading}
              disabled={loading || !formState.isDirty}
              type="submit"
              buttonStyle="green"
            />
          </div>
        </div>
      </div>
    </form>
  );
}
