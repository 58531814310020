import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button, CheckboxInput } from "djinn-components";
import { errorParser } from "../../utils/errorParser";
import { RootState } from "../../store";
import { useAuth } from "../../utils/auth/useAuth";
import axios from "../../plugins/axios";

const NotificationField = ({ title, subtitle, id, value, onChange }: any) => {
  return (
    <label
      htmlFor={id}
      className="bg-gray-700 rounded-xl p-4 flex justify-between items-center w-10/12	cursor-pointer hover:bg-gray-800 transition ease-in-out duration-150 mb-6"
    >
      <div className="w-10/12 flex-shrink-0">
        <h5 className="font-medium text-md text-white">{title}</h5>
        <p className="text-gray-400 text-xs font-light leading-5 mt-1">
          {subtitle}
        </p>
      </div>
      <CheckboxInput id={id} value={value} onChange={onChange} />
    </label>
  );
};

const NotificationsEdit = () => {
  const { fetchUser } = useAuth();

  const user = useSelector<RootState>(
    ({ user: { currentUser } }) => currentUser
  ) as any;

  const getDefaults = useCallback(() => {
    return {
      cp_new_features: user.cp_new_features,
      cp_beta_stuff: user.cp_beta_stuff,
      cp_third_party: user.cp_third_party,
    };
  }, [user]);

  const { handleSubmit, control, setError, formState, reset } = useForm({
    defaultValues: getDefaults(),
  });

  useEffect(() => {
    reset(getDefaults());
  }, [user, getDefaults, reset]);

  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const submitForm = async (values: any) => {
    setLoading(true);

    try {
      await axios.put("me", values);
      await fetchUser();
      alert.show("Settings updated!", { type: "success" });
    } catch (error) {
      errorParser(error, setError);
      alert.show("Failed to update settings!", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <form className="w-full mt-4 h-full" onSubmit={handleSubmit(submitForm)}>
      {/* <Prompt
        when={formState.isDirty}
        message="You have unsaved changes are you sure you want to leave?"
      /> */}
      <div className="flex justify-center items-center p-6 h-4/5">
        <div className="w-2/4 h-4/5 flex flex-col items-center justify-start">
          <div className="w-2/3">
            <h3 className="leading-6 text-2xl text-white">Notifications</h3>
            <p className="mt-3 font-light text-sm leading-5 text-gray-400 w-11/12">
              We will always let you know about important changes, but pick what
              else you would like to hear about here.
            </p>
          </div>
        </div>
        <div className="w-2/4 h-4/5 flex flex-col items-center justify-start">
          <Controller
            name="cp_new_features"
            control={control}
            render={({ field: { onChange, value } }) => (
              <NotificationField
                title="New features"
                subtitle="Be notified anytime new features have been added."
                id="cp_new_features"
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name="cp_beta_stuff"
            control={control}
            render={({ field: { onChange, value } }) => (
              <NotificationField
                title="Beta products &amp; features"
                subtitle="Be notified about products and features that are available for beta testing."
                id="cp_beta_stuff"
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name="cp_third_party"
            control={control}
            render={({ field: { onChange, value } }) => (
              <NotificationField
                title="Third party contact"
                subtitle="Allow third party services to send you relevant notifications. We will always get your permision before giving out your contact details."
                id="cp_third_party"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      </div>

      <div className="flex items-center justify-end mt-10">
        <button
          type="button"
          hidden={!formState.isDirty}
          onClick={() => reset(getDefaults())}
          className="text-sm text-white mr-6 opacity-50 hover:opacity-100 cursor-pointer transition ease-in-out duration-150 focus:outline-none"
        >
          Discard Changes
        </button>
        <div className="w-48">
          <Button
            text="Save Changes"
            loading={loading}
            disabled={loading || !formState.isDirty}
            buttonStyle="green"
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default NotificationsEdit;
