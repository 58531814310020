import React from "react";
import { Controller } from "react-hook-form";
import { labelStyle } from "../../constants/styles";
import FormFieldErrors from "./FormFieldErrors";

const FormFieldWrapper: React.FC<any> = ({
  label,
  id,
  render,
  errors,
  control,
  required = true,
}) => {
  return (
    <>
      <label htmlFor={id} className={labelStyle}>
        {label}
      </label>

      <Controller
        name={id}
        control={control}
        rules={{
          required,
        }}
        render={({ field: { onChange, value } }) =>
          render({ onChange, value, invalid: !!errors[id] })
        }
      />

      <FormFieldErrors
        errors={errors}
        field={id}
        requiredMessage={`${label} is required`}
      />
    </>
  );
};

export default FormFieldWrapper;
