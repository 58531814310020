import { combineReducers } from "redux";
import authReducer from "./auth";
import rolesReducer from "./roles";
import userReducer from "./user";
import usersReducer from "./users";

export default combineReducers({
  user: userReducer,
  users: usersReducer,
  auth: authReducer,
  roles: rolesReducer,
});
