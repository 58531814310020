import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Button, TextInput } from "djinn-components";
import SelectField from "../Inputs/SelectField";
import { errorStyle, labelStyle } from "../../constants/styles";
import ImageUpload from "../Inputs/ImageUpload";
import { Controller, useForm } from "react-hook-form";
import { errorParser } from "../../utils/errorParser";
import ChangePassword from "./ChangePassword";
import { RootState } from "../../store";
import { useAuth } from "../../utils/auth/useAuth";
import axios from "../../plugins/axios";
interface UpdateProfileInputs {
  name: string;
  avatar: string;
}
const ProfileEdit = () => {
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const { fetchUser } = useAuth();
  const user = useSelector(
    ({ user: { currentUser } }: RootState) => currentUser
  );
  const [showChangePassword, setShowChangePassword] = useState(false);

  const getDefaults = useCallback(() => {
    return {
      name: user.name,
      avatar: user.avatar,
    };
  }, [user]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
    formState,
    reset,
  } = useForm<UpdateProfileInputs>({
    reValidateMode: "onChange",
    defaultValues: getDefaults(),
  });

  useEffect(() => {
    reset(getDefaults());
  }, [user, getDefaults, reset]);

  const submitForm = async (values: UpdateProfileInputs) => {
    setLoading(true);

    try {
      await axios.put("me", values);
      await fetchUser();
      alert.show("Profile updated!", { type: "success" });
    } catch (error) {
      errorParser(error, setError);
      alert.show("Failed to update profile!", { type: "error" });
    }

    setLoading(false);
  };

  return (
    <>
      <form
        className="w-full p-4 mt-12 h-full flex flex-col justify-between"
        onSubmit={handleSubmit(submitForm)}
      >
        {/* <Prompt
          when={formState.isDirty}
          message="You have unsaved changes are you sure you want to leave?"
        /> */}
        <div className="flex w-full">
          <div className="w-2/4 px-16 py-4 flex-shrink-0 flex-grow-0">
            <div className="mb-6 w-full">
              <label htmlFor="name" className={labelStyle}>
                Name
              </label>

              <Controller
                name="name"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    id="name"
                    value={value}
                    onChange={onChange}
                    invalid={!!errors.name}
                  />
                )}
              />

              {errors.name && errors.name.type == "required" && (
                <span className={errorStyle}>Name is required</span>
              )}

              {errors.name && errors.name.type == "api" && (
                <span className={errorStyle}>{errors.name.message}</span>
              )}
            </div>

            <div className="mb-6">
              <label htmlFor="email" className={labelStyle}>
                Email address
              </label>

              <TextInput type="email" value={user.email} disabled={true} />
            </div>

            <div className="mb-6 w-full">
              <label htmlFor="email" className={labelStyle}>
                Password
              </label>
              <div className="bg-gray-700 rounded-full flex justify-between items-center">
                <div
                  className="p-4 text-sm text-white"
                  style={{
                    fontSize: "37px",
                    letterSpacing: "3px",
                    lineHeight: 0,
                    fontWeight: 800,
                  }}
                >
                  ∙∙∙∙∙∙∙∙∙
                </div>
                <a
                  className="bg-gray-500 text-center p-4 text-white rounded-r-full text-sm cursor-pointer hover:opacity-75 transition ease-in-out duration-150"
                  onClick={() => setShowChangePassword(true)}
                >
                  Change
                </a>
              </div>
            </div>

            <div className="mb-6">
              <label htmlFor="timezone" className={labelStyle}>
                Time Zone
              </label>
              <SelectField
                selected="gmt"
                options={[
                  {
                    value: "gmt",
                    key: "Greenwich Mean Time (GMT)",
                  },
                ]}
              />
            </div>
          </div>
          <div className="w-2/4 flex-shrink-0 flex-grow-0">
            <div className="w-20">
              <Controller
                name="avatar"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ImageUpload
                    onChange={onChange}
                    value={value}
                    type="user_avatar"
                  />
                )}
              />
            </div>

            {errors.avatar && errors.avatar.type == "api" && (
              <span className={errorStyle}>{errors.avatar.message}</span>
            )}
          </div>
        </div>
        <div className="w-full flex justify-end items-center">
          <button
            type="button"
            hidden={!formState.isDirty}
            onClick={() => reset(getDefaults())}
            className="text-sm text-white mr-6 opacity-50 hover:opacity-100 cursor-pointer transition ease-in-out duration-150 focus:outline-none"
          >
            Discard Changes
          </button>
          <div className="w-56">
            <Button
              text="Save Profile"
              loading={loading}
              disabled={loading || !formState.isDirty}
              type="submit"
              buttonStyle="green"
            />
          </div>
        </div>
      </form>
      {showChangePassword && (
        <ChangePassword onClose={() => setShowChangePassword(false)} />
      )}
    </>
  );
};

export default ProfileEdit;
