import React, {
  ComponentType,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import axios from "../plugins/axios";
import LoadingComponent from "../components/LoadingComponent";

export interface WithApiResourceProps {
  data: any;
  reload: () => void;
  loading: boolean;
}

export function withApiResource<
  T extends WithApiResourceProps = WithApiResourceProps
>(Component: ComponentType<T>, endpoint: string) {
  const ComponentWithApiResource: FunctionComponent<T> = (props) => {
    const [loading, setLoading] = useState(false);
    const [row, setRow] = useState([]);

    const makeApiCall = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(endpoint);
        setRow(data);
      } catch (error) {
        setRow([]);
      }
      setLoading(false);
    };

    useEffect(() => {
      makeApiCall();
    }, []);

    if (loading) return <LoadingComponent />;

    return (
      <Component {...(props as T)} data={row} reload={() => makeApiCall()} />
    );
  };
  return ComponentWithApiResource;
}
