import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import axios from "../../plugins/axios";

const VerifyEmailScreen: React.FC<any> = () => {
  const [status, setStatus] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get("id");
  const token = searchParams.get("token");

  const attemptValidation = useCallback(async () => {
    try {
      await axios.get(`email/verify/${id}/${token}`);
      if (window.localStorage.getItem("token")) {
        navigate("/create-team");
      } else {
        setStatus("success");
      }
    } catch (error) {
      setStatus("failed");
    }
  }, [id, navigate, token]);

  useEffect(() => {
    attemptValidation();
  }, [attemptValidation]);
  const getMessage = () => {
    if (status === null) return "Verifying...";
    if (status === "success") return "Done";

    return "Failed to verify email";
  };

  const getContent = () => {
    if (status === "success" || status === "success_logged_in")
      return "Your email address has been successfully verified, you can now login and view your account.";
    return "If you think this is an error, please contact a member of support at <a href='mailto:help@docbloc.co' class='text-gray-600 hover:text-gray-500'>help@docbloc.co</a>";
  };

  const logoutAndBack = () => {
    window.localStorage.clear();
    window.location.replace("/");
  };

  return (
    <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-900">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src="/icon.png" alt="Docbloc" />
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-200">
          {getMessage()}
        </h2>
      </div>

      {status !== null && (
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <p
              className="mt-4 text-center text-md leading-5 text-gray-700 max-w"
              dangerouslySetInnerHTML={{ __html: getContent() }}
            />

            <p className="mt-4 text-center text-sm leading-5 text-gray-800 max-w">
              <a
                href="/"
                onClick={logoutAndBack}
                className="font-medium text-gray-900 hover:text-gray-1000 focus:outline-none focus:underline transition ease-in-out duration-150"
              >
                Back to login
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerifyEmailScreen;
