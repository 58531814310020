import React, { useCallback, useEffect, useState } from "react";

import CreatePasswordModal from "../../../components/Modals/CreatePassword";
import axios from "../../../plugins/axios";
import useConfirmDelete from "../../../utils/withConfirmDelete";
import { useAlert } from "react-alert";
import ReactTooltip from "react-tooltip";
import HelpLink from "../../../components/HelpLink";
import { Modal, PlusIcon, TrashIcon } from "djinn-components";
import { useProjectState } from "../../../utils/requiresProject";

const ProjectSettingsAccess: React.FC<any> = () => {
  const { project } = useProjectState();
  const alert = useAlert();

  const [showAddPassword, setShowAddPassword] = useState(false);

  const [items, setItems] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  const deletePassword = useConfirmDelete();

  const getItems = useCallback(async () => {
    try {
      const { data } = await axios.get(`project-passwords/${project.id}`);
      setItems(data);
      if (data.length == 0) setShowAddPassword(true);
      ReactTooltip.rebuild();
    } catch (error) {
      alert.error("Failed to load passwords, please contact support");
    }
    setPageLoading(false);
  }, [project.id, alert]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const deletePasswordById = async (id: number) => {
    try {
      await axios.delete(`project-passwords/${id}`);
      getItems();
      alert.show("Password Deleted!", { type: "success" });
    } catch (error) {
      alert.error("Failed to delete password!");
    }
  };

  return (
    <div className="max-w-5xl mx-auto mt-10">
      <table
        className="w-5/6 border-separate"
        style={{ borderSpacing: "0 1.2rem" }}
      >
        <thead>
          <tr>
            <th className="px-6 py-3 text-left text-md font-large text-white tracking-wider">
              Title
            </th>
            <th className="px-6 py-3 text-left text-md font-large text-white tracking-wider">
              Last Used
            </th>
            <th className="px-6 py-3 text-left text-md font-large text-white tracking-wider">
              Created
            </th>
            <th className="px-6 w-10"></th>
          </tr>
        </thead>
        <tbody>
          {items.map((item: any, index) => (
            <tr
              className={"bg-gray-600 rounded-full overflow-hidden"}
              key={index}
            >
              <td className="px-5 py-4 whitespace-no-wrap rounded-l-full text-sm leading-5 font-medium text-white">
                {item.name}
              </td>
              <td className="px-5 py-4 whitespace-no-wrap text-sm font-light leading-5 text-gray-200 opacity-75">
                {item.last_used}
              </td>
              <td className="px-5 py-4 whitespace-no-wrap  text-sm font-light leading-5 text-white opacity-75">
                {item.created_at}
              </td>
              <td className="px-6 py-2 whitespace-no-wrap rounded-r-full text-right text-sm leading-5 font-medium">
                <a
                  onClick={() =>
                    deletePassword("DELETE PASSWORD", () =>
                      deletePasswordById(item.id)
                    )
                  }
                  data-tip="Delete password"
                  data-place="top"
                  className="text-white hover:text-red-600 cursor-pointer"
                >
                  <TrashIcon className="block w-5 h-5" size={20} />
                </a>
              </td>
            </tr>
          ))}

          {pageLoading && (
            <tr>
              <td className="p-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-400">
                Loading...
              </td>
              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-400"></td>
              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-400"></td>
              <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium"></td>
            </tr>
          )}
          {!pageLoading && !items.length && (
            <tr>
              <td className="p-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-400">
                You do not have any passwords setup for this project yet
              </td>
              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-400"></td>
              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-400"></td>
              <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium"></td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="w-5/6 flex justify-between items-center mt-10 mb-1">
        <HelpLink
          text="Need help with your Docbloc availability?"
          link="guides/private-documentation-access"
        />
        <button
          className="h-10 bg-green-600 hover:bg-green-700 rounded-full px-4 flex justify-center items-center text-white text-xs font-medium"
          onClick={() => setShowAddPassword(true)}
        >
          Create New <PlusIcon className="ml-3 w-5 h-5" />
        </button>
      </div>
      <Modal shown={showAddPassword}>
        <CreatePasswordModal
          project={project}
          getItems={getItems}
          onClose={() => {
            setShowAddPassword(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default ProjectSettingsAccess;
