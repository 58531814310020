import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import ProjectEditScreen from "./edit";
import SettingsPage from "./settings";
import ProjectUpgradePage from "./upgrade";

import ProjectDashboard from "../../components/ProjectDashboard";
import ProjectSidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import useCheckProjectPermissions from "../../utils/useCheckProjectPermission";
import { requiresProject, useProjectState } from "../../utils/requiresProject";
import { Modal } from "djinn-components";
import ChooseProjectVersionModal from "../../components/Modals/ProjectVersion/Choose";
import CreateProjectVersionModal from "../../components/Modals/ProjectVersion/Create";

const ProjectScreen: React.FC = () => {
  const { project } = useProjectState();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const [showVersionSelect, setShowVersionSelect] = useState(false);
  const [showVersionCreate, setShowVersionCreate] = useState(false);

  const permission = useCheckProjectPermissions(project);

  return (
    <div className="h-screen flex overflow-hidden w-full text-white">
      <ProjectSidebar
        setSidebarCollapsed={setSidebarCollapsed}
        sidebarCollapsed={sidebarCollapsed}
        project={project}
        permission={permission}
        showVersionSelect={() => setShowVersionSelect(true)}
      />
      <main className="flex-1 relative">
        <Routes>
          <Route
            path={`/`}
            key={`project-dash-${project.id}`}
            element={<ProjectDashboard />}
          />
          <Route
            path={`/edit/*`}
            key={`project-edit-${project.id}`}
            element={<ProjectEditScreen />}
          ></Route>
          {permission("admin") && (
            <>
              <Route
                path={`/settings/*`}
                key={`project-settings-${project.id}`}
                element={<SettingsPage />}
              />

              <Route
                path={`/pro`}
                key={`project-pro-${project.id}`}
                element={
                  <>
                    <Header
                      title="Pro Docbloc Features"
                      subtitle={
                        "Upgrade your Docbloc to pro for enhanced features"
                      }
                    />
                    <ProjectUpgradePage />
                  </>
                }
              ></Route>
            </>
          )}
        </Routes>
      </main>
      <Modal
        shown={showVersionSelect}
        onClose={() => setShowVersionSelect(false)}
        dismissible
      >
        <ChooseProjectVersionModal
          onClose={() => setShowVersionSelect(false)}
          onCreate={() => {
            setShowVersionSelect(false);
            setShowVersionCreate(true);
          }}
        />
      </Modal>

      <Modal
        shown={showVersionCreate}
        onClose={() => setShowVersionCreate(false)}
        dismissible
      >
        <CreateProjectVersionModal
          onClose={() => setShowVersionCreate(false)}
        />
      </Modal>
    </div>
  );
};

export default requiresProject(ProjectScreen);
