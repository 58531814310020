import React from "react";

const AnalyticsBlock = ({ title, value, from }: any) => {
  const getValue = () => {
    if (value == "NaN") return 0;
    return value;
  };

  const getFrom = () => {
    if (from == "NaN") return 0;
    return from;
  };

  const direction = getValue() >= getFrom() ? "up" : "down";

  const getBoxStyle = () => {
    if (getValue() == "..." || getFrom() == "..." || getValue() == getFrom())
      return "bg-gradient-to-b from-gray-500 to-gray-600";
    return direction == "up"
      ? "bg-gradient-to-b from-green-500 to-green-600"
      : "bg-gradient-to-l from-red-500 to-red-600";
  };

  const getPercentStyle = () => {
    if (getValue() == "..." || getFrom() == "..." || getValue() == getFrom())
      return "bg-gray-400";
    return direction == "up" ? "bg-green-400" : "bg-red-400";
  };

  const getPercentageDifference = (n1: number, n2: number) => {
    return (((n2 - n1) / n1) * 100).toLocaleString("fullwide", {
      maximumFractionDigits: 0,
    });
  };

  const getPercentageEl = () => {
    let percent = getPercentageDifference(getFrom(), getValue());

    if (percent == "NaN") return <></>;
    if (!isFinite(parseFloat(percent))) percent = "100";
    return (
      <span
        className={`rounded-full p-2 text-center shadow-xs ${getPercentStyle()} bg-opacity-50 w-20 text-sm font-medium`}
      >
        {percent}%
      </span>
    );
  };

  return (
    <div className={` ${getBoxStyle()} w-full p-8 rounded-xl shadow-2xl mt-8`}>
      <div className="flex justify-between w-full items-center mb-3">
        <h6 className="text-md font-medium text-white">{title}</h6>
        {getPercentageEl()}
      </div>
      <span className="text-4xl font-medium block">{getValue()}</span>
      <span className="text-gray-300 text-sm">from {getFrom()}</span>
    </div>
  );
};

export default AnalyticsBlock;
