import React from "react";
import Form from "../../Form";
import FormFieldWrapper from "../../Form/FormFieldWrapper";
import SelectField from "../../Inputs/SelectField";
import { TextInput } from "djinn-components";
import FormHolder from "../FormHolder";
import { useProjectState } from "../../../utils/requiresProject";
import HelpLink from "../../HelpLink";

const ProjectSettingsDetails: React.FC = () => {
  const { project, reloadProject } = useProjectState();

  const getDefaults = () => {
    return {
      name: project.project.name,
      visibility: project.project.visibility,
      sub_text: project.project.sub_text,
      active_version_code: project.project.active_version_code,
    };
  };

  return (
    <FormHolder>
      <Form
        endpoint={`projects/${project.project.id}`}
        method="put"
        buttonTitle="Save Changes"
        successToast="Project settings update successful"
        getDefaults={getDefaults}
        onCompleted={async () => {
          await reloadProject();
        }}
        render={({ errors, control }: any) => (
          <div className="grid grid-cols-2 gap-4 mb-40">
            <div>
              <FormFieldWrapper
                id="name"
                label="Project name"
                errors={errors}
                control={control}
                render={({ onChange, value, invalid }: any) => (
                  <TextInput
                    value={value}
                    placeholder="Enter project name"
                    onChange={onChange}
                    invalid={invalid}
                  />
                )}
              />
            </div>

            <div>
              <FormFieldWrapper
                id="sub_text"
                label="Page title"
                errors={errors}
                control={control}
                render={({ onChange, value, invalid }: any) => (
                  <TextInput
                    value={value}
                    placeholder=""
                    onChange={onChange}
                    invalid={invalid}
                  />
                )}
              />
            </div>

            <div>
              <FormFieldWrapper
                id="visibility"
                label="Visibility"
                errors={errors}
                control={control}
                render={({ onChange, value, invalid }: any) => (
                  <SelectField
                    selected={value}
                    options={[
                      {
                        key: "Public",
                        value: "public",
                      },
                      {
                        key: "Private",
                        value: "private",
                      },
                      {
                        key: "Draft",
                        value: "draft",
                      },
                    ]}
                    onSelect={(v: any) => onChange(v)}
                    invalid={invalid}
                  />
                )}
              />
            </div>
            <div>
              <FormFieldWrapper
                id="active_version_code"
                label="Active Version"
                errors={errors}
                control={control}
                render={({ onChange, value, invalid }: any) => (
                  <SelectField
                    selected={value}
                    options={project.project.versions.map((ver) => ({
                      value: ver.version_code,
                      key: ver.name,
                    }))}
                    onSelect={(v: any) => onChange(v)}
                    invalid={invalid}
                  />
                )}
              />
              <br />
              <HelpLink
                text="Need help working with project versioning"
                link="guides/project-editor-versions"
              />
            </div>
          </div>
        )}
      />
    </FormHolder>
  );
};

export default ProjectSettingsDetails;
