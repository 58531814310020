import axios from "../../plugins/axios";
import React, {
  ComponentType,
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { ProjectVersionType, TempProjectItemModel } from "../../types/models";
import { useParams } from "react-router-dom";
import AuthLayout from "../../layouts/auth";

export interface PageWithProject {
  project: ProjectVersionType;
  reloadProject: () => Promise<void>;
  tempSetProjectItems: (items: any) => void;
}

const CurrentProjectContext = createContext<PageWithProject>({
  // @ts-expect-error
  project: undefined,
  reloadProject: async () => {},
  tempSetProjectItems: () => {},
});

const ProvideProject = ({
  children,
  project,
  reloadProject,
  tempSetProjectItems,
}: any) => {
  return (
    <CurrentProjectContext.Provider
      value={{ project, reloadProject, tempSetProjectItems }}
    >
      {children}
    </CurrentProjectContext.Provider>
  );
};

export const useProjectState = () => {
  return useContext(CurrentProjectContext);
};

export function requiresProject<T extends object>(Component: ComponentType<T>) {
  const AppWithScopedProject: FunctionComponent<T> = (props) => {
    const params = useParams<{ project: string }>();
    const [project, setProject] = useState<ProjectVersionType>();
    const [loaded, setLoaded] = useState(false);

    const getProject = useCallback(async () => {
      try {
        const { data }: any = await axios.get(
          `project-versions/${params.project}`
        );
        setProject(data);
        setLoaded(true);
      } catch (error) {}
    }, [params.project]);

    useEffect(() => {
      getProject();
    }, [params.project, getProject]);

    const tempSetProjectItems = (items: TempProjectItemModel[]) => {
      setProject({
        ...project,
        all_items: items,
      } as ProjectVersionType);
    };

    if (loaded && !project) return <div>Failed to load project</div>;

    return (
      <ProvideProject
        {...{
          project,
          reloadProject: getProject,
          tempSetProjectItems,
        }}
      >
        <AuthLayout loaded={loaded}>
          <Component {...props} />
        </AuthLayout>
      </ProvideProject>
    );
  };

  return AppWithScopedProject;
}
