import React from "react";

export default function UrlInput({
  value,
  onChange,
  url,
  id = "url",
  invalid = false,
  position = "right",
}: any) {
  return (
    <div
      className={`w-full flex rounded-full shadow-sm bg-gray-700 text-white justify-between items-center sm:text-sm focus-within:ring-white ring-1 transition duration-500 ease-in-out overflow-hidden ${
        invalid ? "ring-red" : "ring-transparent"
      }`}
    >
      {position == "left" && (
        <span className="w-2/4 bg-gray-500 p-4 text-center">{url}</span>
      )}
      <div
        className={`${
          position == "right" ? "w-2/4 text-right" : "w-2/4 text-left"
        }`}
      >
        <input
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          className={`p-4 bg-transparent pl-4 placeholder-gray-400 form-input focus:outline-none w-full border-none focus:border-none ring-0 focus:ring-0`}
        />
      </div>
      {position == "right" && (
        <span className="w-5/12 bg-gray-500 p-4 text-center h-14 flex items-center justify-center">
          {url}
        </span>
      )}
    </div>
  );
}
