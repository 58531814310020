import axios from "axios";
import history from "../utils/history";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

axios.interceptors.request.use(
  (config) => {
    const newConfig = config;
    if (window.localStorage.getItem("token")) {
      newConfig.headers!.Authorization = `Bearer ${window.localStorage.getItem(
        "token"
      )}`;
    }

    if (window.localStorage.getItem("Acc0")) {
      newConfig.headers!["DOCBLOC-ACC0"] = window.localStorage.getItem(
        "Acc0"
      ) as string;
    }

    return newConfig;
  },
  (err) => Promise.reject(err)
);

axios.interceptors.response.use(
  (response) => ({
    data: response.data,
    headers: response.headers,
  }),
  (err) => {
    if (err.response.status === 401) {
      window.localStorage.clear();
      window.location.replace("/");
    }

    if (err.response.status === 403) {
      switch (err.response.data.message) {
        case "Your email address is not verified.":
          history.push("/not-verified");
          break;
        case "select_account":
          history.push("/");
          break;
        case "no_accounts":
          history.push("/");
          break;
        default:
          break;
      }
    }

    if (err.response.status === 404) {
      history.push("/e/404");
    }

    if (err.response.status === 503) {
      history.push("/e/503");
    }

    if (err.response.status === 429) {
      history.push("/e/429");
    }

    return Promise.reject(err);
  }
);

export default axios;
