import React from "react";
import { AccountModel } from "../../types/models";
import { withApiResource } from "../../utils/withApiResource";
import Form from "../Form";
import FormFieldWrapper from "../Form/FormFieldWrapper";
import ImageUpload from "../Inputs/ImageUpload";
import { TextInput } from "djinn-components";
import FormHolder from "../ProjectSettings/FormHolder";

const ManageAccountDetails: React.FC<{
  data: AccountModel;
  reload: () => void;
}> = ({ data, reload }) => {
  const getDefaults = () => {
    return {
      logo: data.logo,
      name: data.name,
    };
  };

  return (
    <FormHolder>
      <Form
        endpoint={`account`}
        method="put"
        buttonTitle="Save Changes"
        successToast="Project settings update successful"
        getDefaults={getDefaults}
        onCompleted={async () => {
          //   await reloadProject();
          reload();
        }}
        render={({ errors, control }: any) => (
          <div className="flex flex-col justify-between items-center py-12 sm:px-6 lg:px-8 w-full">
            <FormFieldWrapper
              id="logo"
              label=""
              errors={errors}
              control={control}
              render={({ onChange, value }: any) => (
                <ImageUpload
                  placeholder="Logo or Avatar"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <div className="max-w-md w-full mt-8">
              <FormFieldWrapper
                id="name"
                label=""
                errors={errors}
                control={control}
                render={({ onChange, value, invalid }: any) => (
                  <TextInput
                    value={value}
                    placeholder="Enter Company/Team name"
                    onChange={onChange}
                    invalid={invalid}
                  />
                )}
              />
            </div>
          </div>
        )}
      />
    </FormHolder>
  );
};

export default withApiResource(ManageAccountDetails, "account");
