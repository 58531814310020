import { RolesModel } from "../types/models";

export interface RolesReduxState {
  list: RolesModel[];
  loading: boolean;
}
const INITIAL_STATE = {
  list: [],
  loading: false,
};

const applySetCurrentRolesList = (state: any, action: any) => ({
  ...state,
  list: action.list,
});

const applySetCurrentLoadingState = (state: any, action: any) => ({
  ...state,
  loading: action.loading,
});

function rolesReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case "ROLES_SET_LIST": {
      return applySetCurrentRolesList(state, action);
    }
    case "ROLES_SET_LOADING": {
      return applySetCurrentLoadingState(state, action);
    }
    default:
      return state;
  }
}

export default rolesReducer;
