import React, { useEffect, useState } from "react";
import { ContextMenu, ContextMenuTrigger } from "react-contextmenu";
import ContextMenuItem from "../../ContextMenu/ContextMenuItem";
import { BsChevronDown, BsThreeDotsVertical } from "react-icons/bs";
import { createModal } from "react-modal-promise";
import EditItemModal from "./EditItemModal";
import useConfirmDelete from "../../../utils/withConfirmDelete";
import {
  TrashIcon,
  DuplicateIcon,
  PencilIcon,
  PlusIcon,
  HierarchyIcon,
} from "djinn-components";

const ParameterFieldNavItem: React.FC<any> = ({
  index,
  param,
  depth = 0,
  parentId = "",
  setParam,
  createItem,
  deleteParam,
  duplicateItem,
}) => {
  const deleteParameter = useConfirmDelete();

  const [expanded, setExpanded] = useState(false);

  const [isMap, setIsMap] = useState(
    ["object_array", "object"].includes(param.type)
  );

  const uniqueId = `${parentId}-${index}`;

  const OldItemModal = (props: any) => <EditItemModal {...props} />;

  const editItemModal = createModal(OldItemModal);

  useEffect(() => {
    setIsMap(["object_array", "object"].includes(param.type));
  }, [param, index]);

  const editParam = async () => {
    const updatedParam = await editItemModal({ param });
    if (updatedParam) {
      setParam(updatedParam);
    }
  };

  const deleteItem = async (delIndex: number) => {
    const currentParam = JSON.parse(JSON.stringify(param));
    currentParam.items = currentParam.items.filter(
      (itm: any, ind: number) => ind !== delIndex
    );
    setParam(currentParam);
  };

  return (
    <div
      className={`${
        depth % 2 == 0 ? "bg-gray-700" : "bg-gray-800"
      } shadow-sm group rounded-md transition ease-in-out duration-150 mb-3 border border-white border-opacity-25`}
    >
      <div
        onClick={() => {
          if (isMap) {
            setExpanded(!expanded);
          } else {
            editParam();
          }
        }}
        className="flex items-center justify-between font-regular h-14 p-4 pr-2 cursor-pointer rounded-md"
      >
        <div className="flex">
          {isMap && (
            <>
              {expanded ? (
                <span className="block w-6 h-6 mr-4">
                  <BsChevronDown size={24} />
                </span>
              ) : (
                <HierarchyIcon className="block w-6 h-6 mr-4" />
              )}
            </>
          )}
          <span className="truncate">{param.name}</span>
        </div>
        <div className="flex justify-center items-center">
          <a
            className="block p-1 px-3 text-xs bg-gray-500 text-white rounded-full mr-2 cursor-pointer hover:bg-gray-600"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              editParam();
            }}
          >
            edit
          </a>
          <ContextMenuTrigger id={uniqueId} mouseButton={0}>
            <a className="w-6 h-14 flex items-center justify-center cursor-pointer text-gray-300 hover:text-white">
              <BsThreeDotsVertical size={18} />
            </a>
          </ContextMenuTrigger>
        </div>
      </div>

      {isMap && (!param.items || param.items.length == 0) && (
        <div className="text-center border-t border-white border-opacity-25 p-4 text-gray-400 font-light text-sm">
          No properties added,{" "}
          <span
            className="font-bold cursor-pointer hover:text-white"
            onClick={async () => {
              const item = await createItem(true);
              if (item) {
                const currentParam = JSON.parse(JSON.stringify(param));
                if (!currentParam.items) currentParam.items = [];
                currentParam.items.push(item);
                setParam(currentParam);
              }
            }}
          >
            create new
          </span>
        </div>
      )}

      {param.items && expanded && (
        <div className="border-t border-white border-opacity-25 p-2 pt-4">
          {param.items.map((param2: any, index2: number) => (
            <ParameterFieldNavItem
              key={`${uniqueId}-${index2}`}
              {...{
                param: param2,
                index: index2,
                depth: depth + 1,
                editParam,
                createItem,
                parentId: uniqueId,
              }}
              setParam={(updatedParam: any) => {
                const currentParam = JSON.parse(JSON.stringify(param));
                currentParam.items[index2] = updatedParam;
                setParam(currentParam);
              }}
              deleteParam={() =>
                deleteParameter("DELETE PROPERTY", () => deleteItem(index2))
              }
              duplicateItem={(newParam: any) => {
                newParam.name = newParam.name + "_copy";

                const currentParam = JSON.parse(JSON.stringify(param));
                currentParam.items.push(newParam);
                setParam(currentParam);
              }}
            />
          ))}
        </div>
      )}
      {expanded && param.items && param.items.length != 0 && (
        <button
          type="button"
          className="block w-5 h-5 mx-auto mb-4 text-white hover:text-green-600 cursor-pointer"
          onClick={async () => {
            const item = await createItem(true);
            if (item) {
              const currentParam = JSON.parse(JSON.stringify(param));
              currentParam.items.push(item);
              setParam(currentParam);
            }
          }}
        >
          <PlusIcon className="" />
        </button>
      )}

      <ContextMenu
        id={uniqueId}
        className="bg-gray-950 py-2 rounded-md shadow-lg w-40 overflow-hidden z-30"
      >
        <ContextMenuItem
          onClick={() => {
            editParam();
          }}
          Icon={PencilIcon}
        >
          Edit parameter
        </ContextMenuItem>

        <ContextMenuItem
          onClick={() => duplicateItem(JSON.parse(JSON.stringify(param)))}
          Icon={DuplicateIcon}
        >
          Duplicate
        </ContextMenuItem>

        <ContextMenuItem
          onClick={() => {
            deleteParam();
          }}
          Icon={TrashIcon}
        >
          Delete
        </ContextMenuItem>
      </ContextMenu>
    </div>
  );
};

export default ParameterFieldNavItem;
