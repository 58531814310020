import React, { useState } from "react";
import { RiPencilFill } from "react-icons/ri";
import { Button } from "djinn-components";
import SelectField from "../../Inputs/SelectField";

export default function EditUserModal({
  changingRole,
  switchUserRole,
  deleteUserById,
  setShowUserOptions,
  deleteUser,
  showUserOptions,
  loading,
}: any) {
  const [role, setRole] = useState(showUserOptions.role);

  const submit = async (e: any) => {
    e.preventDefault();
    await switchUserRole(role);
    setShowUserOptions(null);
  };

  return (
    <>
      <form className="p-8 px-14 pt-3" onSubmit={submit}>
        <div>
          <div className="mx-auto flex items-center justify-center h-8 w-8">
            <RiPencilFill size={16} color="white" />
          </div>
          <div className="text-center mt-2">
            <h3
              className="text-lg leading-6 font-medium text-white"
              id="modal-headline"
            >
              Edit User
            </h3>
            <p className="leading-5 font-light text-xs text-gray-400">
              Edit {showUserOptions.name}'s settings below
            </p>

            <div className="mt-8 relative">
              <SelectField
                selected={role}
                label="Role Select"
                options={[
                  { value: "admin", key: "Admin" },
                  { value: "user", key: "User" },
                ]}
                onSelect={(v: any) => {
                  setRole(v);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex mt-8">
          <Button
            text="Save Changes"
            type="submit"
            buttonStyle="green"
            loading={changingRole}
            disabled={changingRole}
          />
        </div>
        <div className="flex mt-3">
          <Button
            text="Delete user"
            type="button"
            buttonStyle="red"
            loading={loading}
            disabled={loading}
            onClick={() => deleteUser("DELETE USER", deleteUserById)}
          />
        </div>
      </form>
    </>
  );
}
