import React, { useState } from "react";
import { BsChevronDown, BsThreeDotsVertical } from "react-icons/bs";
import MenuItem from ".";
import {
  Droppable,
  Draggable,
  DraggingStyle,
  DraggableProvided,
} from "react-beautiful-dnd";
import { itemFilter } from "../../../pages/project/edit/utils";
import { ContextMenu, ContextMenuTrigger } from "react-contextmenu";
import FolderWithCount from "../../Icons/FolderWithCount";
import ContextMenuItem from "../../ContextMenu/ContextMenuItem";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../plugins/axios";
import { DuplicateIcon, PencilIcon } from "djinn-components";

const getItemStyle = (
  isDragging: boolean,
  isDraggingOver: boolean,
  draggableStyle: DraggingStyle
) => ({
  ...draggableStyle,
  backgroundColor: isDraggingOver ? "black" : "transparent",
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "black" : "transparent",
  // border: isDraggingOver ? "1px solid " : "0px solid transparent",
});

const MenuItemGroup = ({
  item,
  onClick,
  search,
  activeString,
  isGettingDumpedOn,
  onReload,
}: any) => {
  const params = useParams<{ project: string }>();
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);

  const getChildItemType = (item1: any) => {
    switch (item1.content_model) {
      case "ProjectEndpoint":
        return "endpoint";
      case "ProjectGroup":
        return "group";
      case "ProjectContentPage":
        return "page";
      default:
        return "None";
    }
  };
  const getItemsL = (items: any) => {
    return Object.values(items);
  };

  const handleClick = () => {
    navigate(`/project/${params.project}/edit/group/${item.id}`);
  };

  const onGroupDuplicate = async () => {
    try {
      const { data } = await axios.post(`groups/${item.id}/duplicate`);
      await onReload();
      navigate(`/project/${params.project}/edit/group/${data.id}`);
    } catch (error) {}
  };

  return (
    <div
      className={`mb-1 rounded transition ease-in-out duration-300 ring-0 ring-transparent focus:outline-none focus:border-none outline-none ${
        expanded ? "bg-gray-900" : "bg-gray-800"
      } overflow-hidden`}
    >
      <div
        className={`link text-sm text-white font-regular leading-5 overflow-hidden h-14 flex justify-between items-center pl-2 ${
          isGettingDumpedOn || expanded ? "bg-gray-900" : "bg-gray-800"
        }`}
        onClick={() => setExpanded(!expanded)}
      >
        <div className="flex justify-start items-center pv-3">
          <div className="w-8 h-8 flex justify-center items-center flex-shrink-0">
            {expanded ? (
              <BsChevronDown />
            ) : (
              <FolderWithCount
                className="w-5 h-5 flex-shrink-0"
                count={item.items.length}
              />
            )}
          </div>
          <div className="ml-3">
            <span className="block text-sm font-bold leading-5">
              {item.name}
            </span>
            <span className="block text-xs truncate">
              {
                getItemsL(item.items).filter((item1) =>
                  itemFilter(item1, search)
                ).length
              }{" "}
              Items
            </span>
          </div>
        </div>
        <ContextMenuTrigger id={`group-context-${item.id}`} mouseButton={0}>
          <a className="w-6 h-14 flex items-center justify-center cursor-pointer text-gray-500 hover:text-white">
            <BsThreeDotsVertical size={18} />
          </a>
        </ContextMenuTrigger>
      </div>
      <div className="px-2">
        <Droppable
          droppableId={`group-${item.id}`}
          isDropDisabled={!expanded}
          isCombineEnabled
        >
          {(droppableProvided, droppableSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              style={getListStyle(droppableSnapshot.isDraggingOver)}
              {...droppableProvided.droppableProps}
            >
              {expanded && getItemsL(item.items).length == 0 && (
                <div className="p-2 pl-4 text-xs truncate border border-gray-450 bg-transparent text-white mb-2 rounded border-dashed">
                  No items
                </div>
              )}
              {expanded && getItemsL(item.items).length > 0 && (
                <div className="pb-1">
                  {getItemsL(item.items)
                    .filter((item1) => itemFilter(item1, search))
                    .map((item1: any, index2) => (
                      <Draggable
                        key={`${getChildItemType(item1)}-${item1.id}`}
                        index={index2}
                        draggableId={`${getChildItemType(item1)}-${item1.id}`}
                      >
                        {(
                          draggableProvided: DraggableProvided,
                          draggableSnapshot
                        ) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            style={getItemStyle(
                              draggableSnapshot.isDragging,
                              droppableSnapshot.isDraggingOver,
                              draggableProvided.draggableProps
                                .style as DraggingStyle
                            )}
                          >
                            <MenuItem
                              item={item1}
                              type={getChildItemType(item1)}
                              key={`${getChildItemType(item1)}-${index2}`}
                              onClick={onClick}
                              search={search}
                              activeString={activeString}
                              onReload={onReload}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                </div>
              )}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      <ContextMenu
        id={`group-context-${item.id}`}
        className="bg-gray-950 py-2 rounded-md shadow-lg w-40 overflow-hidden"
      >
        <ContextMenuItem onClick={handleClick} Icon={PencilIcon}>
          Edit group
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() => setExpanded(!expanded)}
          Icon={PencilIcon}
        >
          {expanded ? "Minimize" : "Expand"}
        </ContextMenuItem>
        <ContextMenuItem onClick={onGroupDuplicate} Icon={DuplicateIcon}>
          Duplicate
        </ContextMenuItem>
      </ContextMenu>
    </div>
  );
};

export default MenuItemGroup;
