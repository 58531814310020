import { arrayMoveImmutable } from "array-move";

/**
 * Move an item from one position in an array to another.
 *
 *
 * @param arr
 * @param old_index
 * @param new_index
 * @returns []
 */
export function arrayMove<ObjectType>(
  arr: Array<ObjectType>,
  old_index: number,
  new_index: number
) {
  return arrayMoveImmutable(arr, old_index, new_index);
}

export function recursiveSearchAndEdit<ObjectType>(
  arr: Array<ObjectType>,
  key: keyof ObjectType,
  val: string,
  childKey: keyof ObjectType,
  updateFn: (v: any) => any
): Array<ObjectType> {
  let newArray = [...arr];
  // @ts-expect-error this is because of how types work
  const foundKey = arr.findIndex((va) => va[key] === val);
  if (foundKey === -1) {
    newArray = newArray.map((ni) => {
      return {
        ...ni,
        [childKey]: recursiveSearchAndEdit(
          // @ts-expect-error this is because of how types work
          ni[childKey] ?? [],
          key,
          val,
          childKey,
          updateFn
        ),
      };
    });
  } else {
    newArray[foundKey] = updateFn(newArray[foundKey]);
  }

  return newArray;
}

export const recursiveObjectFind = (
  arr: Array<any>,
  key: string,
  val: string,
  childKey: string
): any => {
  const foundKey = arr.findIndex((va) => va[key] === val);
  let childFound = null;

  if (foundKey === -1) {
    arr.forEach((ii) => {
      const vv = recursiveObjectFind(ii[childKey] ?? [], key, val, childKey);
      if (vv) childFound = vv;
    });
  } else {
    childFound = arr[foundKey];
  }

  return childFound;
};

export const insertItemAtArrayPosition = (
  arr: Array<any>,
  item: any,
  index: number
) => {
  const nvArr = [...arr, item];
  const oldIndex = nvArr.indexOf(
    (ii: any) => JSON.stringify(ii) === JSON.stringify(item)
  );

  return arrayMove(nvArr, oldIndex, index);
};
