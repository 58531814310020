import React from "react";
import { errorStyle } from "../../constants/styles";
import get from "lodash.get";
const FormFieldErrors: React.FC<any> = ({
  errors,
  field,
  requiredMessage = "",
}) => {
  const error = get(errors, field);

  return (
    <>
      {error && error.type === "required" && (
        <span className={errorStyle}>
          {requiredMessage ?? `The ${field} is required to continue`}
        </span>
      )}
      {error && error.type === "api" && (
        <span className={errorStyle}>{error.message}</span>
      )}
    </>
  );
};

export default FormFieldErrors;
