import React, { useRef, useState } from "react";
import axios from "axios";
import { useAlert } from "react-alert";

import { LoadingIcon, PencilCircleFilled } from "djinn-components";

const ImageUpload: React.FC<any> = ({
  placeholder = "Upload Photo",
  value,
  onChange,
  type = "account_avatar",
}) => {
  const alert = useAlert();

  const imageRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [uploadedFullUrl, setUploadedFullUrl] = useState(value ?? null);

  const chooseImage = () => {
    // @ts-expect-error
    imageRef.current.click();
  };

  const saveImage = async (event: any) => {
    const images = event.target.files;

    if (!images[0]) return;

    setLoading(true);

    const formData = new FormData();
    formData.append("file", images[0]);

    try {
      const { data } = await axios.post(`files/${type}`, formData);
      onChange(data.path);
      setUploadedFullUrl(data.url);
    } catch (error) {
      alert.show("Image does not meet requirements", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <div
      className="w-full flex flex-col items-center justify-center text-gray-500 relative cursor-pointer"
      onClick={chooseImage}
    >
      <p className="text-xs mb-3">{placeholder}</p>
      <div className="relative">
        {loading ? (
          <div
            className={`h-20 w-20 flex items-center justify-center text-primary`}
          >
            <LoadingIcon size={20} />
          </div>
        ) : (
          <img
            src={uploadedFullUrl ?? "/user-placeholder.png"}
            alt="ImageUploadField"
            className={`h-20 w-20 inline-block rounded-full shadow-lg object-cover`}
          />
        )}
        <PencilCircleFilled className="w-10 h-10 absolute  -bottom-3 -right-3 text-green-600" />
      </div>
      <input
        ref={imageRef}
        type="file"
        id="image"
        name="image"
        hidden
        accept="image/*"
        onChange={saveImage}
      />
    </div>
  );
};

export default ImageUpload;
