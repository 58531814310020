import React from "react";
import Form from "../../Form";
import FormFieldWrapper from "../../Form/FormFieldWrapper";
import { TextInput } from "djinn-components";
import UrlInput from "../../Inputs/UrlInput";
import FormHolder from "../FormHolder";
import { useProjectState } from "../../../utils/requiresProject";
import useFeatureCheck from "../../../utils/useCheckFeature";
import { Link } from "react-router-dom";
import CustomDomainConfig from "../../CustomDomainConfig";

const ProjectSettingsDomains: React.FC = () => {
  const { project, reloadProject } = useProjectState();
  const featureCheck = useFeatureCheck();

  const getDefaults = () => {
    return {
      domain: project.project.domain,
      custom_url: project.project.custom_url,
    };
  };

  return (
    <FormHolder>
      <Form
        endpoint={`projects/${project.project.id}`}
        method="put"
        buttonTitle="Save Changes"
        successToast="Project domain settings updated successfully"
        getDefaults={getDefaults}
        onCompleted={async () => {
          await reloadProject();
        }}
        render={({ errors, control }: any) => (
          <div className="w-2/4">
            <div className="mb-6">
              <FormFieldWrapper
                id="domain"
                label="Docs URL"
                errors={errors}
                control={control}
                render={({ onChange, value, invalid }: any) => (
                  <UrlInput
                    id="domain"
                    url=".docbloc.co"
                    value={value}
                    onChange={onChange}
                    invalid={invalid}
                  />
                )}
              />
            </div>

            <div className="mb-6">
              <FormFieldWrapper
                id="custom_url"
                label="Custom Domain"
                errors={errors}
                control={control}
                required={false}
                render={({ onChange, value, invalid }: any) => (
                  <TextInput
                    value={value}
                    placeholder="Enter domain name"
                    onChange={onChange}
                    invalid={invalid}
                    disabled={!featureCheck("custom_domain")}
                  />
                )}
              />
              {!featureCheck("custom_domain") && (
                <p className="text-red-500 text-xs font-medium mt-3 ml-4">
                  Only projects on the pro plan can have custom domains,{" "}
                  <Link
                    to={`/project/${project.id}/pro`}
                    className="text-red-600 font-extrabold"
                  >
                    {" "}
                    Upgrade now
                  </Link>
                </p>
              )}
              {project.project.custom_url && (
                <CustomDomainConfig project={project.project} />
              )}
            </div>
          </div>
        )}
      />
    </FormHolder>
  );
};

export default ProjectSettingsDomains;
