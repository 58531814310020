import React from "react";
import { TextInput, Button } from "djinn-components";
import { errorStyle } from "../../../constants/styles";
import { AiFillGithub, AiOutlineGoogle } from "react-icons/ai";
import { Controller, useForm } from "react-hook-form";
import axios from "../../../plugins/axios";
import { RegisterFormInputs } from "../../../pages/auth/register";

interface RegisterFormDetailsProps {
  loading: boolean;
  setLoading: (v: boolean) => void;
  alert: any;
  userData: {
    name: string;
    email: string;
  } | null;
  validateAndNext: (data: any) => void;
}
export default function RegisterDetailsForm({
  loading,
  setLoading,
  alert,
  validateAndNext,
  userData,
}: RegisterFormDetailsProps) {
  const getCurrentVals = () => {
    if (userData != null) {
      return userData;
    }
    return {
      name: "",
      email: "",
    };
  };

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm<RegisterFormInputs>({
    defaultValues: getCurrentVals(),
  });

  const onFinish = (values: any) => {
    setLoading(true);
    return axios
      .post("/register/check", values)
      .then(() => {
        validateAndNext(values);
      })
      .catch(
        ({
          response: {
            data: { errors: resErrors },
          },
        }) => {
          if (resErrors.email)
            setError("email", { type: "api", message: resErrors.email[0] });

          alert.show("Incorrect Login details", { type: "error" });
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const getSocialLoginRoute = (provider: "google" | "github") => {
    return `${process.env.REACT_APP_API_URL}auth/social/${provider}`;
  };
  return (
    <form onSubmit={handleSubmit(onFinish)} className="w-full max-w-sm">
      <div className="text-gray-300 text-xs w-full text-center mb-10">
        Step <strong>1</strong> of <strong>2</strong>
      </div>
      <Controller
        name="name"
        control={control}
        defaultValue=""
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <TextInput
            id="name"
            value={value}
            onChange={onChange}
            invalid={!!errors.name}
            placeholder="Full name"
          />
        )}
      />

      {errors.name && errors.name.type == "required" && (
        <span className={errorStyle}>Full name is required</span>
      )}

      {errors.name && errors.name.type == "api" && (
        <span className={errorStyle}>{errors.name.message}</span>
      )}

      <div className="mt-6 rounded-md shadow-sm">
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern:
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          }}
          render={({ field: { onChange, value } }) => (
            <TextInput
              type="email"
              value={value}
              placeholder="Email Address"
              onChange={onChange}
              invalid={!!errors.email}
            />
          )}
        />

        {errors.email && errors.email.type == "required" && (
          <span className={errorStyle}>Email address is required</span>
        )}
        {errors.email && errors.email.type == "pattern" && (
          <span className={errorStyle}>Please enter a valid email address</span>
        )}

        {errors.email && errors.email.type == "api" && (
          <span className={errorStyle}>{errors.email.message}</span>
        )}
      </div>

      <div className="mt-12">
        <Button
          text="Next"
          type="submit"
          buttonStyle="green"
          loading={loading}
          disabled={loading}
        />
      </div>

      <div className="flex flex-col items-center justify-center mt-10 border-t border-gray-600 pt-10">
        <span className="font-light text-sm text-gray-400">
          Or, create an account with
        </span>
        <div className="grid gap-4 grid-cols-2 mt-4">
          <a
            href={getSocialLoginRoute("github")}
            className="rounded-full py-3 px-2 bg-white text-black flex items-center justify-center font-bold text-sm w-36 hover:bg-black hover:text-white transition ease-in-out duration-150"
          >
            <AiFillGithub size={20} />
            &nbsp;GitHub
          </a>
          <a
            href={getSocialLoginRoute("google")}
            className="rounded-full py-3 px-2 bg-white text-black flex items-center justify-center font-bold text-sm w-36 hover:bg-red-800 hover:text-white transition ease-in-out duration-150"
          >
            <AiOutlineGoogle size={20} />
            &nbsp;Google
          </a>
        </div>
      </div>
    </form>
  );
}
