const INITIAL_STATE = {
  currentUser: null,
  currentAccount: null,
};

const applySetCurrentUser = (state: any, action: any) => ({
  ...state,
  currentUser: action.currentUser,
});

const applySetCurrentAccount = (state: any, action: any) => ({
  ...state,
  currentAccount: action.currentAccount,
});

function userReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case "USER_SET_CURRENT_USER": {
      return applySetCurrentUser(state, action);
    }
    case "USER_SET_CURRENT_ACCOUNT": {
      return applySetCurrentAccount(state, action);
    }
    default:
      return state;
  }
}

export default userReducer;
