import { PlusIcon } from "djinn-components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useProjectState } from "../../../utils/requiresProject";
import VersionSelectIcon from "../../Icons/VersionIcon";

interface ChooseProjectVersionModalProps {
  onClose: () => void;
  onCreate: () => void;
}

const ChooseProjectVersionModal: React.FC<ChooseProjectVersionModalProps> = ({
  onClose,
  onCreate,
}) => {
  const { project } = useProjectState();
  const navigate = useNavigate();
  return (
    <div>
      <div className="mx-auto flex items-center justify-center h-10 w-10">
        <VersionSelectIcon />
      </div>
      <div className="mt-2 mb-6 text-center">
        <h3 className="text-xl text-white">Select Project Version</h3>
      </div>
      {project.project.versions.map((version) => (
        <button
          key={version.id}
          onClick={() => {
            if (version.id !== project.id) {
              navigate(`/project/${version.id}`);
            }
            onClose();
          }}
          className={`text-white rounded-full py-3 px-5 w-full mb-4 ${
            version.id === project.id
              ? "bg-gray-900 cursor-default"
              : "bg-gray-750 hover:bg-gray-700 cursor-pointer"
          } flex items-center justify-between`}
        >
          <div>
            <span className="text-gray-400">Version:</span> {version.name}
          </div>
          <div className="text-xs font-medium">
            {version.version_status !== "CURRENT" && version.version_status}
          </div>
        </button>
      ))}
      <div className="flex items-center justify-end mt-4 w-full">
        <button
          className="h-8 mb-1 bg-green-600 hover:bg-green-700 rounded-full px-3 flex justify-center items-center text-white text-xs ring-0 ring-transparent focy"
          onClick={() => onCreate()}
        >
          New <PlusIcon className="ml-2 w-4 h-4" size={16} />
        </button>
      </div>
    </div>
  );
};

export default ChooseProjectVersionModal;
