import React, { useState } from "react";
import axios from "../../../plugins/axios";
import { useAlert } from "react-alert";
import { Button, SquarePlusIcon, TextInput } from "djinn-components";
import { Controller, useForm } from "react-hook-form";
import { errorParser } from "../../../utils/errorParser";
import { errorStyle } from "../../../constants/styles";
import { useBus } from "react-bus";
import { useNavigate } from "react-router-dom";

export default function CreateProjectModal({ onComplete, reload }: any) {
  const alert = useAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const bus = useBus();

  const getDefaults = () => {
    return {
      name: null,
    };
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: getDefaults(),
  });

  const submit = async (values: any) => {
    setLoading(true);

    try {
      const { data } = await axios.post(`projects`, values);
      reload();
      onComplete();
      alert.show("Project created!", { type: "success" });
      navigate(`/project/${data.id}`);
    } catch (error) {
      errorParser(error, setError);
      alert.show("Failed to create project!", { type: "error" });
    }
    setLoading(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit(submit)} className="p-5 px-12">
        <div className="mx-auto">
          <div>
            <div className="mx-auto flex items-center justify-center h-10 w-10">
              <SquarePlusIcon />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-xl text-white">Create a new project</h3>
              <div className="mt-2">
                <p className="text-sm font-light leading-5 text-gray-450">
                  Enter details below to create a new project. To learn how to
                  get started with demo projects,{" "}
                  <a
                    className="hover:text-white font-bold cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      bus.emit(
                        "open-help-link",
                        "guides/how-to-add-a-new-project"
                      );
                    }}
                  >
                    click here
                  </a>
                </p>
              </div>
              <div className="mt-6 text-left">
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      id="name"
                      placeholder="Name"
                      value={value}
                      onChange={onChange}
                      invalid={!!errors.name}
                    />
                  )}
                />
                {errors.name && errors.name.type == "required" && (
                  <span className={errorStyle}>Project name is required</span>
                )}

                {errors.name && errors.name.type == "api" && (
                  <span className={errorStyle}>{errors.name.message}</span>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <span className="flex w-full rounded-md shadow-sm">
              <Button
                text="Create project"
                loading={loading}
                disabled={loading}
                type="submit"
                buttonStyle="green"
              />
            </span>
          </div>
        </div>
      </form>
    </>
  );
}
